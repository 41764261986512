import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Grid,
  Stack,
  Tooltip,
  Divider,
} from "@mui/joy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IAddon,
  ISelectedAddon,
  ITicketBasket,
  ITicketRelease,
} from "../../../../types";
import StyledText from "../../../text/styled_text";
import usePalette from "../../../../theme/use_palette";
import { useTranslation } from "react-i18next";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import StyledButton from "../../../buttons/styled_button";
import { BorderColor } from "@mui/icons-material";
import {
  selectAccentColor,
  selectMainColor,
} from "../../../../redux/features/managerThemeSlice";
import { useSelector } from "react-redux";

interface TicketReleaseAddonsProps {
  ticketRelease: ITicketRelease;
  selectedAddons: ISelectedAddon[][]; // Now each ticket has its own set of addons
  handleChange: (ticketIndex: number, updatedAddons: ISelectedAddon[]) => void; // Handles addon changes per ticket
  basketTickets?: ITicketBasket[]; // The tickets in the basket
  immutable?: boolean; // Optional field to define if addons can be modified
}

const TicketReleaseAddons: React.FC<TicketReleaseAddonsProps> = ({
  ticketRelease,
  selectedAddons,
  handleChange,
  basketTickets,
  immutable = false, // Default value is false
}) => {
  const { t } = useTranslation();
  const { add_ons } = ticketRelease;
  const palette = usePalette();

  const accentColor = useSelector(selectAccentColor);

  if (!add_ons || !basketTickets) {
    return null;
  }

  return (
    <Box mt={2}>
      <StyledText level="h3" color={palette.primaryDark} fontSize={20}>
        {t("event.ticket_release.add_ons.title")}
      </StyledText>
      <StyledText level="body-sm" color={palette.charcoal} fontSize={16}>
        {t("event.ticket_release.add_ons.description")}
      </StyledText>

      {/* Loop through each ticket in the basket */}
      {basketTickets.map((ticket, ticketIndex) =>
        Array.from({ length: ticket.quantity }, (_, quantityIndex) => {
          // Determine background color based on the index (alternating styles)
          const accordionStyle = {
            border: "2px dotted",
            borderColor: accentColor,
            borderRadius: "5px",
            p: 1,
            my: 1,
          };

          return (
            <Accordion
              key={`${ticketIndex}-${quantityIndex}`}
              defaultExpanded={ticketIndex === 0}
              sx={accordionStyle} // Apply alternating background style
            >
              <AccordionSummary>
                <StyledText
                  level="h5"
                  color={palette.charcoal}
                  fontSize={18}
                  fontWeight={700}
                >
                  {t("event.ticket_release.add_ons.ticket_number", {
                    number: ticketIndex + 1, // Show ticket number per quantity
                  })}
                </StyledText>
              </AccordionSummary>
              <AccordionDetails>
                {/* Display addons for this specific ticket */}
                {add_ons.map((addon: IAddon) => {
                  const selectedAddonForTicket = selectedAddons[
                    ticketIndex
                  ]?.find((selectedAddon) => selectedAddon.id === addon.id);

                  return (
                    <Box
                      key={addon.id}
                      mt={2}
                      sx={{
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        p: 1,
                      }}
                    >
                      <Grid
                        container
                        justifyContent={"flex-start"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        <Grid>
                          <Checkbox
                            name={addon.name + addon.id}
                            checked={!!selectedAddonForTicket}
                            disabled={immutable}
                            onChange={() => {
                              if (immutable) return;

                              const isAlreadySelected =
                                !!selectedAddonForTicket;

                              if (isAlreadySelected) {
                                handleChange(
                                  ticketIndex,
                                  selectedAddons[ticketIndex].filter(
                                    (selectedAddon) =>
                                      selectedAddon.id !== addon.id
                                  )
                                );
                              } else {
                                handleChange(ticketIndex, [
                                  ...selectedAddons[ticketIndex],
                                  {
                                    id: addon.id,
                                    quantity: 1,
                                  },
                                ]);
                              }
                            }}
                          />
                        </Grid>
                        <Grid>
                          <Tooltip title={addon.name} placement="right-start">
                            <span>
                              <StyledText
                                color={palette.charcoal}
                                fontSize={20}
                                level="body-sm"
                                fontWeight={600}
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  maxWidth: "500px", // Adjust this value to suit your needs
                                }}
                              >
                                {addon.name}
                                <StyledText
                                  level="body-sm"
                                  startDecorator={<HorizontalRuleIcon />}
                                  color={palette.charcoal}
                                  fontSize={18}
                                  fontWeight={500}
                                  sx={{
                                    ml: 1,
                                  }}
                                >
                                  SEK {addon.price}
                                </StyledText>
                              </StyledText>
                            </span>
                          </Tooltip>
                        </Grid>
                        {!!selectedAddonForTicket && !immutable && (
                          <Stack
                            spacing={2}
                            direction={"row"}
                            alignItems={"center"}
                            ml={2}
                          >
                            <StyledButton
                              size="sm"
                              color={palette.charcoal}
                              bgColor={palette.errorMain}
                              fs={40}
                              style={{
                                height: "30px",
                              }}
                              disabled={selectedAddonForTicket.quantity === 0}
                              onClick={() => {
                                if (selectedAddonForTicket.quantity === 1) {
                                  // Disable the ticket addon by removing it
                                  handleChange(
                                    ticketIndex,
                                    selectedAddons[ticketIndex].filter(
                                      (selectedAddon) =>
                                        selectedAddon.id !== addon.id
                                    )
                                  );
                                } else {
                                  handleChange(
                                    ticketIndex,
                                    selectedAddons[ticketIndex].map(
                                      (selectedAddon) =>
                                        selectedAddon.id === addon.id
                                          ? {
                                              ...selectedAddon,
                                              quantity:
                                                selectedAddon.quantity - 1,
                                            }
                                          : selectedAddon
                                    )
                                  );
                                }
                              }}
                            >
                              -
                            </StyledButton>
                            <StyledText
                              color={palette.charcoal}
                              fontSize={20}
                              level="body-sm"
                              fontWeight={700}
                            >
                              {selectedAddonForTicket.quantity}
                            </StyledText>
                            <StyledButton
                              size="sm"
                              color={palette.charcoal}
                              bgColor={palette.successLight}
                              fs={40}
                              style={{
                                height: "30px",
                              }}
                              disabled={
                                selectedAddonForTicket.quantity ===
                                addon.max_quantity
                              }
                              onClick={() => {
                                handleChange(
                                  ticketIndex,
                                  selectedAddons[ticketIndex].map(
                                    (selectedAddon) =>
                                      selectedAddon.id === addon.id
                                        ? {
                                            ...selectedAddon,
                                            quantity:
                                              selectedAddon.quantity + 1,
                                          }
                                        : selectedAddon
                                  )
                                );
                              }}
                            >
                              +
                            </StyledButton>
                          </Stack>
                        )}
                      </Grid>
                      <StyledText
                        level="body-sm"
                        color={palette.charcoal}
                        fontSize={14}
                        sx={{
                          mt: 1,
                        }}
                      >
                        {addon.description}{" "}
                        <StyledText
                          level="body-sm"
                          color={palette.charcoalTransparent}
                          fontSize={14}
                          fontWeight={600}
                        >
                          (
                          {t("event.ticket_release.add_ons.max_quantity", {
                            max: addon.max_quantity,
                          })}
                          ){" "}
                          {addon.contains_alcohol &&
                            "(" +
                              t(
                                "event.ticket_release.add_ons.contains_alcohol"
                              ) +
                              ")"}
                        </StyledText>
                      </StyledText>
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </Box>
  );
};

export default TicketReleaseAddons;