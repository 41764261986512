import { FormLabel } from "@mui/joy";
import StyledText from "../text/styled_text";
import usePalette from '../../theme/use_palette';

export const StyledFormLabel: React.FC<{
  children: React.ReactNode;
  fontSize?: number;
  overrideStyles?: React.CSSProperties;
}> = ({ children, overrideStyles = {}, fontSize = 20 }) => {
  const palette = usePalette();
  return (
    <FormLabel style={overrideStyles ? overrideStyles : {}}>
      <StyledText
        level="body-md"
        fontSize={fontSize}
        color={palette.charcoal}
        fontWeight={700}
      >
        {children}
      </StyledText>
    </FormLabel>
  );
};

export const StyledFormLabelWithHelperText: React.FC<{
  fontSize?: number;
  children: React.ReactNode;
}> = ({ children, fontSize }) => {
  const palette = usePalette();
  return (
    <FormLabel>
      <StyledText
        level="body-md"
        fontSize={fontSize ? fontSize : 16}
        color={palette.charcoal}
        style={{
          marginTop: 5,
          overflowWrap: "break-word",
          width: "100%",
        }}
      >
        {children}
      </StyledText>
    </FormLabel>
  );
};