import React, { useEffect } from "react";
import { ISelectedAddon, ITicketBasket, ITicketRelease } from "../../../types";
import { Box, Button, Divider, Grid, Link, Stack, Typography } from "@mui/joy";
import usePalette from "../../../theme/use_palette";

import TicketReleaseCountdown from "./tr_countdown";
import TicketType from "../ticket_types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  ShoppingCartItem,
  postTicketOrderRequest,
} from "../../../redux/features/ticketOrderSlice";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import WhaIsTicketRequestModal from "./what_is_ticket_request";
import { toast } from "react-toastify";
import StyledButton from "../../buttons/styled_button";
import { numberOfTotalTicketRequestInBasket } from "../../../utils/ticket_types";
import { Trans, useTranslation } from "react-i18next";
import StyledText from "../../text/styled_text";
import TicketReleaseAddons from "./addons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InformationModal from "../../modal/information";
import { useMediaQuery, useTheme } from "@mui/material";
import MakeTicketOrderWorkflow from "./ticket_request/make_ticket_request_work_flow";
import { createGuestTicketOrderRequest } from "../../../redux/features/guestCustomerSlice";
import { LEGAL_LINKS } from "../../legal/legal_links";
import { requiresUpfrontPayment } from "../../../utils/manage_event/can_edit_payment_deadline";
import { resetLoginSuccess } from "../../../redux/features/authSlice";

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const TicketReleaseHasOpened: React.FC<{
  ticketRelease: ITicketRelease;
}> = ({ ticketRelease }) => {
  const { items: ticketOrderItems, loading: makingRequest } = useSelector(
    (state: RootState) => state.ticketOrder
  ) as {
    items: ShoppingCartItem[];
    loading: boolean;
  };

  const [basket, setBasketItems] = React.useState<ITicketBasket[]>([]);
  const [whatIsRequestOpen, setWhatIsRequestOpen] = React.useState(false);
  const [requestedTickets, setRequestedTickets] = React.useState<
    {
      ticket_type_id: number;
      addons: ISelectedAddon[];
    }[]
  >([]);
  const [makeTicketOrderModalOpen, setMakeTicketOrderModalOpen] =
    React.useState(false);

  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { guestCustomer } = useSelector((state: RootState) => state.auth);

  const palette = usePalette();

  useEffect(() => {
    const basketItems: ITicketBasket[] = [];

    setBasketItems((prevBasket) => {
      const newBasket = [...prevBasket];

      ticketOrderItems.forEach((item, ticketIndex) => {
        const ticketType = ticketRelease.ticket_types?.find(
          (tt) => tt.id === item.ticket.id
        );

        if (ticketType) {
          // For each ticket, ensure it is treated individually
          for (let i = 0; i < item.quantity; i++) {
            const basketItem: ITicketBasket = {
              id: ticketType.id,
              name: ticketType.name,
              quantity: 1,
              price: ticketType.price,
              type: "ticket", // Ensure the type is explicitly set to the string "ticket"
              addons: newBasket[ticketIndex]?.addons || [], // Preserve existing addons or initialize with empty
            };

            basketItems.push(basketItem);
          }
        }
      });

      return basketItems;
    });
  }, [ticketOrderItems, ticketRelease.ticket_types]);

  const handleMakeRequest = debounce(() => {
    const tickets = basket.map((ticket) => ({
      ticket_type_id: ticket.id,
      addons: ticket.addons, // Include selected addons for each ticket
    }));

    if (tickets.length === 0) {
      toast.info("No tickets selected");
      return;
    }

    setRequestedTickets(tickets);
    setMakeTicketOrderModalOpen(true);
  }, 300); // 300ms delay

  useEffect(() => {}, [makeTicketOrderModalOpen]);

  const getPromoCodes = () => {
    let existingPromoCodes: string[] = [];
    if (existingPromoCodes) {
      existingPromoCodes = JSON.parse(
        localStorage.getItem("promo_codes") || "[]"
      );
    } else {
      existingPromoCodes = [];
    }
    return existingPromoCodes ?? [];
  };

  const onSubmit = () => {
    dispatch(
      postTicketOrderRequest({
        promoCodes: getPromoCodes(),
        tickeOrderReq: {
          ticket_release_id: ticketRelease.id!,
          tickets: requestedTickets, // Tickets with their addons
        },
        addons: [], // No need to pass a top-level addons array, since addons are now in each ticket
        eventId: ticketRelease.event_id,
      })
    );
  };

  const onGuestSubmit = () => {
    dispatch(
      createGuestTicketOrderRequest({
        promoCodes: getPromoCodes(),
        ticketsOrderReq: {
          ticket_release_id: ticketRelease.id!,
          tickets: requestedTickets, // Tickets with their addons
        },
        eventId: ticketRelease.event_id,
        guestCustomer: guestCustomer!,
      })
    );
  };

  // Handle addon change for a specific ticket in the basket
  const handleAddonChange = (
    ticketIndex: number, // Index of the specific ticket in the basket
    updatedAddons: ISelectedAddon[] // Updated addons for this specific ticket
  ) => {
    setBasketItems((prevBasket) => {
      // Create a copy of the previous basket array
      const newBasket = [...prevBasket];

      // Ensure the current ticket has its own addons array initialized
      if (!newBasket[ticketIndex].addons) {
        newBasket[ticketIndex].addons = [];
      }

      // Update the addons for the specific ticket
      newBasket[ticketIndex].addons = updatedAddons;

      // Return the updated basket
      return newBasket;
    });
  };

  useEffect(() => {
    dispatch(resetLoginSuccess());
  }, [dispatch]);

  const requireDirectPayment = requiresUpfrontPayment(
    ticketRelease.ticket_release_method_detail.ticket_release_method!
  );

  return (
    <>
      <InformationModal
        isOpen={makeTicketOrderModalOpen}
        onClose={() => {
          setMakeTicketOrderModalOpen(false);
        }}
        title={t(
          "event.ticket_release.request_process.complete_ticket_request"
        )}
        width={isScreenSmall ? "100%" : "60%"}
        preventCloseReasons={["backdropClick"]}
      >
        <Box>
          <MakeTicketOrderWorkflow
            ticketRelease={ticketRelease}
            onSubmitTicketOrder={onSubmit}
            onSubmitGuestTicketOrder={onGuestSubmit}
            onClose={() => {
              setMakeTicketOrderModalOpen(false);
            }}
            payDirect={requireDirectPayment}
          />
        </Box>
      </InformationModal>

      {/* {makingRequest && <LoadingOverlay />} */}
      <Stack spacing={2} sx={{ p: 0 }} mt={2}>
        {ticketRelease.ticket_types!.length > 0 ? (
          ticketRelease.ticket_types!.map((ticketType, i) => {
            const key = `${ticketType.id}-${i}`;
            return (
              <TicketType
                ticketType={ticketType}
                maxTicketsPerUser={
                  ticketRelease.ticket_release_method_detail
                    ?.max_tickets_per_user
                }
                key={key}
              />
            );
          })
        ) : (
          <Typography level="body-sm" fontFamily={"Josefin sans"}>
            {t("event.ticket_release.no_tickets")}
          </Typography>
        )}
      </Stack>
      {ticketRelease.add_ons && ticketRelease.add_ons.length > 0 && (
        <TicketReleaseAddons
          ticketRelease={ticketRelease}
          handleChange={handleAddonChange}
          selectedAddons={basket.map((ticket) => ticket.addons)}
          basketTickets={basket}
        />
      )}
      {basket! && (
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Typography
            level="h4"
            fontFamily={"Josefin Sans"}
            mt={2}
            style={{
              color: palette.charcoal,
            }}
          >
            {t("event.ticket_release.checkout.overview")}
          </Typography>
          {basket?.map((item, index) => (
            <React.Fragment key={`${item.name}-${index}`}>
              <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Render ticket info */}
                <Grid
                  container
                  justifyContent={"flex-start"}
                  flexDirection={"row"}
                >
                  <LocalActivityIcon />
                  <StyledText
                    level="body-sm"
                    sx={{
                      ml: 2,
                    }}
                    color={palette.charcoal}
                  >
                    {item.quantity} x {item.name}
                  </StyledText>
                </Grid>
                <StyledText level="body-sm" color={palette.charcoal}>
                  SEK {(item.price * item.quantity).toFixed(2)}
                </StyledText>
              </Grid>

              {/* Render addons for this ticket */}
              {item.addons?.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  {item.addons.map((addon, addonIndex) => (
                    <Grid
                      container
                      key={`addon-${addon.id}`}
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        key={`addon-${addon.id}`}
                        sx={{
                          ml: 2,
                        }}
                      >
                        <AddCircleOutlineIcon sx={{ mr: 2 }} />
                        <StyledText
                          level="body-sm"
                          color={palette.charcoal}
                          fontSize={14}
                        >
                          {addon.quantity} x{" "}
                          {ticketRelease.add_ons?.find((a) => a.id === addon.id)
                            ?.name || "Addon"}
                        </StyledText>
                      </Stack>
                      <StyledText
                        level="body-sm"
                        color={palette.charcoal}
                        fontSize={14}
                      >
                        SEK{" "}
                        {(ticketRelease.add_ons?.find((a) => a.id === addon.id)
                          ?.price || 0) * addon.quantity}
                      </StyledText>
                    </Grid>
                  ))}
                </Box>
              )}
            </React.Fragment>
          ))}

          <Divider
            sx={{
              mb: 1,
              width: "100%",
            }}
          />
          {/* Total */}
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <Grid container justifyContent={"flex-start"} flexDirection={"row"}>
              <ShoppingCartIcon />
              <StyledText
                level="body-sm"
                fontSize={16}
                color={palette.charcoal}
                sx={{
                  ml: 2,
                }}
              >
                {t("event.ticket_release.checkout.total")}
              </StyledText>
            </Grid>
            <StyledText level="body-sm" fontSize={16} color={palette.charcoal}>
              SEK{" "}
              {basket
                ?.reduce((acc, item) => {
                  // Calculate ticket price
                  const ticketCost = item.price * item.quantity;

                  // Calculate addons price
                  const addonCost =
                    item.addons?.reduce((addonAcc, addon) => {
                      const addonPrice =
                        ticketRelease.add_ons?.find((a) => a.id === addon.id)
                          ?.price || 0;
                      return addonAcc + addonPrice * addon.quantity;
                    }, 0) || 0;

                  // Add ticket cost and addon cost
                  return acc + ticketCost + addonCost;
                }, 0)
                .toFixed(2)}
            </StyledText>
          </Grid>

          <Grid
            mt={4}
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <StyledButton
              size="lg"
              onClick={handleMakeRequest}
              bgColor={palette.successLight}
              color={palette.alwaysBlack}
              disabled={
                numberOfTotalTicketRequestInBasket(
                  ticketOrderItems,
                  ticketRelease.id!
                ) === 0
              }
            >
              {t(
                !requireDirectPayment
                  ? "form.button_request"
                  : "form.button_purchase"
              )}
            </StyledButton>
          </Grid>

          <Box mt={1}>
            <StyledText
              level="body-sm"
              fontWeight={500}
              fontSize={14}
              color={palette.greyLight}
              sx={{
                textWrap: "balance",
              }}
            >
              <Trans i18nKey="event.ticket_release.information_processing_policy_info">
                By requesting a ticket you agree to share your food preferences
                and user details with the event organizer until the event is
                over. Information collected will be processed in accordance with
                the Chapter's information processing policy,
                <Link href={LEGAL_LINKS.privacy_policy} target="_blank">
                  PRIVACY POLICY
                </Link>
                <Link href={LEGAL_LINKS.refund_policy} target="_blank">
                  REFUND POLICY
                </Link>
                <Link href={LEGAL_LINKS.terms_and_conditions} target="_blank">
                  TERMS AND CONDITIONS
                </Link>
              </Trans>
            </StyledText>
          </Box>

          <WhaIsTicketRequestModal
            isOpen={whatIsRequestOpen}
            onClose={() => {
              setWhatIsRequestOpen(false);
            }}
          />
        </Box>
      )}
      <div
        style={{
          marginTop: ".5rem",
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <StyledText
          level="body-sm"
          fontWeight={600}
          fontSize={15}
          color={palette.charcoal}
        >
          {t("event.ticket_release.tickets_available_for")}:
        </StyledText>
        <TicketReleaseCountdown
          ticketRelease={ticketRelease}
          fw={500}
          fs={16}
          useOpen={false}
        />
      </div>
    </>
  );
};

export default TicketReleaseHasOpened;
