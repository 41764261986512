import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Chip,
  useColorScheme,
  Button,
  IconButton,
} from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import {
  selectAccentColor,
  selectMainColor,
  selectTextColor,
} from "../../redux/features/managerThemeSlice";
import NavLinks from "./nav_links";
import AuthButtons from "./auth_buttons";
import LanguageSelector from "./language_selector";
import StyledText from "../text/styled_text";
import MobileNavigationBar from "./mobile-nav";
import { adjustTransparency } from "../../utils/manager/color";
import { INavigationBarOptions, INetwork } from "../../types";
import { Style } from "@mui/icons-material";
import {
  ConstructNormalRoute,
  IsUsingSubdomain,
  RemoveSubdomainFromRoute,
} from "../../utils/subdomain/subdomain_utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/def";
import { useThemeContext } from "../../theme/theme_context";
import usePalette from "../../theme/use_palette";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { ThemeToggleButton } from "./theme_toggle_button";
import { AppDispatch, RootState } from "../../store";
import { getNetworkRequest } from "../../redux/features/manager/networkSlice";
import { useDispatch } from "react-redux";
interface NavigationBarProps {
  options?: INavigationBarOptions;
  shouldUseDefaultColor?: boolean;
  isJoyTheme?: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  options,
  shouldUseDefaultColor = false,
  isJoyTheme = true,
}) => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery("(max-width:1000px)");
  const palette = usePalette();
  const dispatch: AppDispatch = useDispatch();
  const { network } = useSelector((state: RootState) => state.network);

  const netMainColor = useSelector(selectMainColor);
  const netTextColor = useSelector(selectTextColor);

  const mainColor = shouldUseDefaultColor ? palette.primaryMain : netMainColor;
  const textColor = shouldUseDefaultColor ? palette.black : netTextColor;

  const isEventPage = options?.isEventPage ?? false;
  const isDashboard = options?.isDashboard ?? false; // New isDashboard flag
  const usingSubdomain = IsUsingSubdomain();
  const navigate = useNavigate();

  const backgroundColor = isDashboard
    ? "transparent" // Make background transparent if on the dashboard
    : isEventPage
    ? palette.primaryMain
    : mainColor;

  if (isScreenSmall) {
    return <MobileNavigationBar />;
  }

  return (
    <Box
      sx={{
        bgcolor: backgroundColor,
        color: "white",
        width: "100%",
        height: isEventPage ? "auto" : "64px",
        position: isEventPage || isDashboard ? "absolute" : "fixed",
        top: 0,
        zIndex: 1000,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent={isEventPage ? "center" : "space-between"}
        sx={{
          padding: isEventPage ? "8px" : "16px",
          marginLeft: "16px",
          marginRight: "16px",
        }}
      >
        {/* Left-aligned logo */}
        <Stack direction="row" alignItems="center" spacing={10}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {isEventPage ? (
              <Stack direction="column" spacing={-1.75}>
                <StyledText
                  color={textColor}
                  level="h4"
                  fontSize={12}
                  sx={{
                    textDecoration: "none",
                    textAlign: "center",
                    opacity: 0.6,
                  }}
                >
                  Powered by
                </StyledText>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid>
                    <StyledText
                      color={textColor}
                      level="h4"
                      onClick={() => {
                        window.location.assign(
                          ConstructNormalRoute(ROUTES.MAIN)
                        );
                      }}
                      fontSize={24}
                      sx={{ textDecoration: "none" }}
                    >
                      tessera
                    </StyledText>
                  </Grid>
                  <Chip
                    sx={{
                      backgroundColor: palette.primaryDark,
                    }}
                  >
                    <StyledText
                      level="body-sm"
                      fontSize={14}
                      fontWeight={800}
                      color={palette.offWhite}
                    >
                      BETA 2
                    </StyledText>
                  </Chip>
                </Grid>
              </Stack>
            ) : !isDashboard ? (
              <>
                <StyledText
                  color={textColor}
                  level="h4"
                  onClick={() => window.location.replace("/")}
                  fontSize={24}
                  sx={{ textDecoration: "none" }}
                >
                  tessera
                </StyledText>
                <Chip
                  sx={{
                    backgroundColor: palette.primaryDark,
                  }}
                >
                  <StyledText
                    level="body-sm"
                    fontSize={14}
                    fontWeight={800}
                    color={palette.offWhite}
                  >
                    BETA 2
                  </StyledText>
                </Chip>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "8px",
                  }}
                >
                  <StyledText
                    color={palette.primaryMain}
                    level="h4"
                    onClick={() => navigate(ROUTES.MAIN)}
                    fontSize={24}
                    sx={{ textDecoration: "none" }}
                  >
                    tessera
                  </StyledText>
                </Box>
              </>
            )}
          </Stack>
          {/* Conditionally hide NavLinks when on the dashboard */}
          {!isEventPage && !isDashboard && (
            <Box>
              <NavLinks textColor={textColor} />
            </Box>
          )}
        </Stack>

        {/* Right-aligned language selector and auth buttons */}
        {!isEventPage ? (
          <Stack direction="row" spacing={2} alignItems="center">
            <LanguageSelector />
            <ThemeToggleButton />
            <AuthButtons
              mainColor={mainColor}
              textColor={textColor}
              showLogin={options?.showLogin ?? true}
            />
          </Stack>
        ) : (
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "16px",
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <LanguageSelector />
              <ThemeToggleButton />
              <AuthButtons
                mainColor={mainColor}
                textColor={textColor}
                isEventPage={isEventPage}
                showLogin={options?.showLogin ?? true}
                showBookDemo={!isEventPage}
              />
            </Stack>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default NavigationBar;
