import { ErrorMessage } from "formik";
import StyledText from "../text/styled_text";
import usePalette from '../../theme/use_palette';
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Stack } from "@mui/joy";

interface StyledErrorMessageProps {
  name: string;
  fontSize?: number;
}

export const StyledErrorMessage: React.FC<StyledErrorMessageProps> = ({
  name,
  fontSize = 16,
}) => {
  const palette = usePalette();
  return (
    <ErrorMessage
      name={name}
      render={(msg) => {
        return (
          <Stack direction="row" spacing={1} mt={0.5}>
            <InfoOutlined style={{ color: palette.errorMain, fontSize: 20 }} />
            <StyledText color={palette.errorMain} fontSize={16} level="body-sm">
              {msg}
            </StyledText>
          </Stack>
        );
      }}
    />
  );
};