import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Link, Stack } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { AppDispatch, RootState } from "../../../../store";
import {
  customerSignupRequest,
  resetGuestCustomer,
  resetLoginSuccess,
  resetSignupSuccess,
} from "../../../../redux/features/authSlice";
import {
  getGuestCustomerRequest,
  resetGuestCustomer as resetGuestCustomerSlice,
  resetRequestSuccess,
} from "../../../../redux/features/guestCustomerSlice";
import {
  resetError,
  resetNewlyCreatedTicketOrder,
  resetPostSuccess,
} from "../../../../redux/features/ticketOrderSlice";

import MakeTicketRequestUserDetails from "./make_ticket_request_user_details";
import MakeTicketRequestFormDetails from "./make_ticket_order_form_details";

import StyledText from "../../../text/styled_text";
import StyledButton from "../../../buttons/styled_button";
import usePalette from "../../../../theme/use_palette";

import { ticketReleaseRequiresAccount } from "../../../../utils/manage_event/can_edit_payment_deadline";
import {
  ICustomerSignupValues,
  IEvent,
  ITicketRelease,
} from "../../../../types";
import { handlePay } from "../../../../handlers/paymentHandler";
import EditFormFieldResponse from "../../form_field_response";
import { fetchFormFieldsRequest } from "../../../../redux/features/formFieldsSlice";
import { ConstructNormalRoute } from "../../../../utils/subdomain/subdomain_utils";

interface MakeTicketOrderWorkflowProps {
  ticketRelease: ITicketRelease;
  onSubmitGuestTicketOrder: () => void;
  onSubmitTicketOrder: () => void;
  onClose: () => void;
  payDirect?: boolean;
}

const MakeTicketOrderWorkflow: React.FC<MakeTicketOrderWorkflowProps> = ({
  ticketRelease,
  onSubmitTicketOrder,
  onSubmitGuestTicketOrder,
  onClose,
  payDirect = false,
}) => {
  const { refID } = useParams<{ refID: string }>();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const palette = usePalette();

  const [eventRefID, setEventRefID] = useState<string | null>(null);

  // State variables
  const [activeStep, setActiveStep] = useState(0);
  const [accountIsRequired, setAccountIsRequired] = useState<boolean>(false);

  const { event } = useSelector(
    (state: RootState) => state.customerViewEvent
  ) as {
    event: IEvent | null;
  };

  // Redux state selectors
  const {
    customerSignupSuccess,
    customerLoginSuccess,
    isLoggedIn,
    guestCustomer,
    loading,
    error: authError,
  } = useSelector((state: RootState) => state.auth);

  const { guestCustomer: fetchedGuestCustomer, create_ticket_request_success } =
    useSelector((state: RootState) => state.guestCustomer);

  const {
    formFields,
    loading: ffloading,
    error,
  } = useSelector((state: RootState) => state.formFields);

  const {
    postSuccess,
    error: postError,
    newlyCreatedTicketOrder,
  } = useSelector((state: RootState) => state.ticketOrder);

  const { ticketOrders: madeTicketOrders } = useSelector(
    (state: RootState) => state.myTicketOrders
  );

  // Reset function to clear states
  const reset = () => {
    dispatch(resetSignupSuccess());
    dispatch(resetLoginSuccess());
    dispatch(resetGuestCustomer());
    dispatch(resetRequestSuccess());
    dispatch(resetError());
    dispatch(resetPostSuccess());
    dispatch(resetNewlyCreatedTicketOrder());

    setActiveStep(0);
  };

  useEffect(() => {
    if (authError) {
      reset();
      onClose();
    }
  }, [authError, dispatch]);

  useEffect(() => {
    if (refID) {
      setEventRefID(refID);
    } else if (event) {
      setEventRefID(event.reference_id!);
    }
  }, [refID, event]);

  useEffect(() => {
    if (ticketRelease.event) {
      dispatch(fetchFormFieldsRequest({ eventId: ticketRelease.event_id }));
    }
  }, [dispatch, ticketRelease.event_id]);

  // Handlers
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSignupContinue = (values: ICustomerSignupValues) => {
    dispatch(customerSignupRequest(values));
  };

  const handleLoginContinue = () => {
    handleNext();
  };

  const handleClose = () => {
    dispatch(resetSignupSuccess());
    dispatch(resetLoginSuccess());
    onClose();
  };

  // Check if account is required
  useEffect(() => {
    const requiresAccount = ticketReleaseRequiresAccount(
      ticketRelease.ticket_release_method_detail.ticket_release_method!
    );
    setAccountIsRequired(requiresAccount);
  }, [ticketRelease]);

  // Reset states on component mount
  useEffect(() => {
    reset();
  }, []);

  // Handle guest customer ticket request
  useEffect(() => {
    if (guestCustomer !== null && !loading) {
      onSubmitGuestTicketOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestCustomer]);

  // Handle ticket request success
  useEffect(() => {
    if (create_ticket_request_success) {
      dispatch(resetRequestSuccess());
      handleNext();
    }
  }, [create_ticket_request_success, dispatch]);

  // Handle ticket order post success
  useEffect(() => {
    if (postSuccess) {
      handleNext();
      dispatch(resetPostSuccess());
    }
  }, [postSuccess, dispatch]);

  // Submit ticket order if logged in
  useEffect(() => {
    if (isLoggedIn && !loading) {
      onSubmitTicketOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  // Handle post error
  useEffect(() => {
    if (postError) {
      dispatch(resetError());
      dispatch(resetPostSuccess());
      handleClose();
    }
  }, [postError, dispatch]);

  // Handle customer signup and login success
  useEffect(() => {
    if (customerSignupSuccess) {
      dispatch(resetSignupSuccess());
      handleNext();
    }
    if (customerLoginSuccess) {
      dispatch(resetLoginSuccess());
      onSubmitTicketOrder();
    }
  }, [
    customerLoginSuccess,
    customerSignupSuccess,
    dispatch,
    onSubmitTicketOrder,
  ]);

  // Handle navigation and state reset based on active step
  useEffect(() => {
    const isGuestCustomer = guestCustomer !== null;

    // After collecting ticket form details
    if (activeStep === 1 && !ticketRelease.event?.collect_food_preferences) {
      setTimeout(handleNext, 200);
      return;
    }

    // After submitting the ticket request
    if (activeStep === 2) {
      setTimeout(handleNext, 1000);
      if (isGuestCustomer) {
        dispatch(
          getGuestCustomerRequest({
            ugkthid: guestCustomer?.id!,
            request_token: guestCustomer?.request_token!,
          })
        );
      } else {
        dispatch(resetGuestCustomer());
      }
      dispatch(resetSignupSuccess());
      dispatch(resetLoginSuccess());
      return;
    }

    // If there are no form fields, skip to the next step
    if (activeStep === 3) {
      const hasFormFields = isGuestCustomer
        ? fetchedGuestCustomer?.ticket_orders?.[0]?.ticket_release?.event
            ?.form_fields?.length! > 0
        : madeTicketOrders?.[0]?.ticket_release?.event?.form_fields?.length! >
          0;

      if (!hasFormFields) {
        handleNext();
      }
    }

    // Final navigation
    if (activeStep === 4) {
      if (payDirect && newlyCreatedTicketOrder) {
        handlePay(
          newlyCreatedTicketOrder,
          eventRefID!,
          !isLoggedIn,
          !isLoggedIn ? guestCustomer : null
        );

        dispatch(resetNewlyCreatedTicketOrder());
        return;
      } else {
      }

      setTimeout(() => {
        if (isGuestCustomer && guestCustomer) {
          window.location.assign(
            ConstructNormalRoute(
              `/events/${eventRefID!}/guest/${guestCustomer.id}?request_token=${
                guestCustomer.request_token
              }&pay_direct=${payDirect}`
            )
          );
        } else if (!isGuestCustomer && newlyCreatedTicketOrder) {
          window.location.assign(
            ConstructNormalRoute(
              `/profile?tab=tickets&ticket_request_id=${newlyCreatedTicketOrder.id}&created=true`
            )
          );
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  // Reset guest customer on component mount
  useEffect(() => {
    dispatch(resetGuestCustomerSlice());
  }, [dispatch]);

  // Render content based on active step
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <MakeTicketRequestUserDetails
            accountIsRequired={accountIsRequired}
            ticketRelease={ticketRelease}
            onSignupContinue={handleSignupContinue}
            onLoginContinue={handleLoginContinue}
          />
        );
      case 1:
        return (
          <MakeTicketRequestFormDetails
            ticketRelease={ticketRelease}
            onContinue={handleNext}
          />
        );
      case 2:
        return <LoadingScreen message="Creating your ticket request..." />;
      case 3:
        return renderFormFieldResponse();
      case 4:
        return <LoadingScreen message="Loading your ticket request..." />;
      default:
        return null;
    }
  };

  // Loading Screen Component
  const LoadingScreen = ({ message }: { message: string }) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "300px",
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="success" size="lg" variant="plain" />
        <StyledText color={palette.primaryMain} level="h2" fontSize={28}>
          {message}
        </StyledText>
      </Stack>
    </Box>
  );

  // Render form field responses
  const renderFormFieldResponse = () => {
    const isGuestCustomer = guestCustomer !== null;

    const tickets = isGuestCustomer
      ? fetchedGuestCustomer?.ticket_orders?.[0]?.tickets
      : newlyCreatedTicketOrder?.tickets;

    return (
      <Box>
        <EditFormFieldResponse
          tickets={tickets!}
          eventID={ticketRelease.event_id}
          formFields={formFields}
          isGuestCustomer={isGuestCustomer}
        />

        {!payDirect ? (
          <StyledText
            color={palette.charcoal}
            level="body-sm"
            fontSize={18}
            fontWeight={500}
            sx={{ mt: 2 }}
          >
            <Trans i18nKey="event.ticket_request_success_description">
              You can view your ticket requests{" "}
              <Link href="/profile?tab=tickets" target="_blank">
                here
              </Link>
              .
            </Trans>
          </StyledText>
        ) : (
          <StyledText
            color={palette.charcoal}
            level="body-sm"
            fontSize={18}
            fontWeight={500}
            sx={{ mt: 2 }}
          >
            {t("event.ticket_order_pay_direct_form_later")}
          </StyledText>
        )}
        <StyledButton
          size="lg"
          onClick={handleNext}
          bgColor={palette.primaryMain}
          color={palette.offBlack}
          sx={{ mt: 2 }}
        >
          {payDirect
            ? t("event.continue_pay")
            : t("event.continue_ticket_orders")}
        </StyledButton>
      </Box>
    );
  };

  return <Box>{renderStepContent()}</Box>;
};

export default MakeTicketOrderWorkflow;