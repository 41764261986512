import { de } from "date-fns/locale";
import gbFaq from "../assets/faq/gb_faq.json";

const enTranslations = {
  navigation: {
    events: "Events",
    create_event: "Create Event",
    teams: "Teams",
    contact: "Contact",
    login: "Login",
    product: "Product",
    pricing: "Pricing",
    manager: "Manager",
  },

  become_a_manager: {
    welcome: "Welcome to tessera!",
    proceed:
      "Are you an event manager? Complete your profile and start creating events.",
    continue_as_manager: "Continue as Manager",
    customer: "Customer",
    manager: "Event Manager",
    skip: "I'm here to buy tickets, Skip",
    select_plan: "Select Plan",
    choose_plan:
      "Choose the plan that works best for you. When contacting us, please specify which plan you are interested in and what additional information you need.",
    full_list_of_features: "Full List of Features",
    selected_plan: "Selected Plan",
    register: {
      on_board:
        "Great to have you on board! Please fill out the form below to become and event manager.",
      free: {
        title: "Free Plan",
      },
      form: {
        company_name: "Company Name",
        company_name_helperText: "What is the name of your company?",
        referral_source: "How did you hear about us?",
      },
    },
  },

  book_demo: {
    button_text: "Book Demo",
    title: "Book a Demo",
    description:
      "Fill out the form below to book a demo. We will contact you shortly to schedule a time.",
    name: "Name",
    name_helperText: "What is your name?",
    email: "Email",
    email_helperText: "What is your email?",
    phone: "Phone",
    phone_helperText: "What is your phone number?",
  },

  product: {
    titles: {
      ticket_distribution: "Ticket Distribution",
      event_management: "Event Management",
      event_pages: "Event Pages",
      team_management: "Team Management",
      beta_program: "BETA Program",
      manager_onboarding: "Manager Onboarding",
      event_forms: "Event Forms",
      data_security: "Data Security",
      payments: "Payments",
    },
    main: {
      more_features: "More Features",
      title: "Discover <1>tessera</1>'s Features",
      description:
        "Explore how tessera can transform your event management experience.",
      about_title: "What is tessera?",
      about_description:
        "tessera is an innovative platform designed to simplify event management, ticket distribution, and attendee engagement. With a suite of powerful features, tessera empowers event organizers to create seamless and memorable experiences.",
      about_description_2:
        "tessera keps its manangers and attendees in mind, providing a user-friendly platform that streamlines the event planning process. With tessera BETA, you can affect the future of event management by providing feedback and suggestions to our team. All types of feedback are welcome! Do you want a new feature? Let us know!",
      features: {
        title: "Key Features",
        more_coming_soon:
          "...and many more we are excited to share with you soon!",
      },
    },
    ticket_distribution: {
      title: "Ticket Distribution",
      description:
        "Flexible and Fair Ticket Distribution Methods for Events of All Sizes",
      section_1: {
        title: "Innovative Ticket Distribution",
        description:
          "tessera offers flexible ticketing options, including lotteries and first-come-first-served methods. This ensures a fair and transparent distribution, no matter the event size.",
        description_2:
          "If you're looking for a customizable ticket allocation system, tessera is the right choice for you. Let us discuss your needs and find the best solution for your event.",

        cards: {
          card_1: {
            title: "Lotteries",
            description:
              "Create a lottery ticket release to ensure a fair and transparent distribution of tickets.",
          },
          card_2: {
            title: "First-Come-First-Served",
            description:
              "Use the first-come-first-served method to distribute tickets to the fastest users.",
          },
          card_3: {
            title: "Reserved Ticket Releases",
            description:
              "Create reserved ticket releases with promo codes for specific users.",
          },
          card_4: {
            title: "Customizable Ticket Allocation",
            description:
              "Customize ticket allocation methods to suit your event's needs.",
          },
          card_5: {
            title: "Traditional Upfront Payments",
            description:
              "We offer a traditional upfront purchase method for ticket releases.",
          },
        },
      },
      section_2: {
        title: "Because it's fair",
        description:
          "tessera offers a fair and transparent ticket distribution system. Events where large numbers of people are interested in attending can be difficult to manage. tessera's innovative ticket distribution methods ensure that everyone has an equal chance of getting a ticket.",
        description_2:
          "Traditional ticket distribution methods such as upfront payments are also available. tessera offers a variety of ticket distribution methods to suit your event's needs.",
      },
    },
    event_management: {
      title: "Event Management",
      description: "Effortless Event Management for All Your Events",
      section_1: {
        title: "Effortless Event Management",
        description:
          "Creating and managing events has never been easier. With tessera’s streamlined system, you can set up events, release tickets, and handle attendees in just a few clicks.",
        description_2:
          "tessera offers a range of features to help you manage your event, from creating ticket releases to tracking attendees. Let us help you make your event a success.",
        description_3:
          "Define when your event begin, and when it ends. Create a ticket release and count down to the release together with your users. Define the ticket types and how they are distributed. Add custom fields to your event form to collect additional information from your users.",
      },
      section_2: {
        title: "Because it's simple",
        description:
          "tessera simplifies event management by providing a user-friendly platform for creating and managing events. With tessera, you can easily set up ticket releases, manage attendees, and track ticket sales.",
        description_2:
          "Our platform is designed to make event management as simple and straightforward as possible. Let us help you create a successful event.",
      },
    },
    customizable_event_pages: {
      title: "Customizable Event Pages",
      description:
        "Create Beautiful, Branded Event Pages to Showcase Your Event",
      section_1: {
        title: "Fully Customizable Event Pages",
        description:
          "Create beautiful, branded event pages with tessera’s integrated page builder. Customize the layout, colors, and content to create a unique event page that reflects your brand. Use our custom componets or add your own HTML and CSS.",
        description_2:
          "With tessera's integrated page builder, you can ensure that no two event pages look the same. Let us help you create an event page that stands out.",
      },
      section_2: {
        title: "Because it's easy",
        description:
          "Simply enter the users email and assign them a role and they will receive an invitation to join your organization. Once they have accepted the invitation, you can assign them to different teams can start collaborating on events.",
        description_2:
          "Our platform is designed to be easy to use, so you can create a beautiful event page without any technical expertise. Let us help you create an event page that stands out.",
      },
    },
    team_management: {
      title: "Team Management",
      description: "Effortlessly Manage Your Teams and Collaborate on Events",
      section_1: {
        title: "Effortless Team Management",
        description:
          "tessera makes it easy to manage teams and collaborate on events. When setting up your organization, you can create teams for different events and assign roles to team members. This ensures that everyone is on the same page and working towards a common goal.",
        description_2:
          "Invite members to join your organization and assign them to teams. Define roles and permissions to ensure that everyone has the right level of access. Let us help you manage your teams and collaborate on events. Lets setup your organization so you can start inviting members and creating teams today!",
      },
      section_2: {
        title: "Because it's collaborative",
        description:
          "tessera is designed to be a collaborative platform for event planning. With tessera, you can create teams, add members, and assign roles to streamline your event planning process.",
        description_2:
          "Our platform is designed to make it easy for teams to collaborate on event planning. Let us help you create a successful event.",
      },
    },
    beta_program: {
      title: "tessera <1>BETA</1>",
      description:
        "Join the tessera BETA Program and Help Shape the Future of Event Management",
      section_1: {
        title: "tessera BETA",
        description:
          "tessera is currently in BETA, meaning it is still being developed and improved. If your organization is interested in using tessera BETA, we will set up a team for you free of charge. You will have access to all the features of tessera, including ticket distribution, event management, and team collaboration.",
        description_2:
          "Join the tessera BETA program and help shape the future of event management. Let us help you create a successful event.",
      },
      section_2: {
        title: "Because it's innovative",
        description:
          "tessera is an innovative platform that is constantly evolving to meet the needs of event organizers. With tessera BETA, you can help shape the future of event management by providing feedback and suggestions to our team.",
        description_2:
          "Our platform is designed to be innovative and forward-thinking, so you can create successful events that meet the needs of your attendees.",
      },
    },
    secure_payment: {
      title: "<1>Secure</1> Payment Processing",
      description: "Seamlessly integrate secure payments into your events",
      section_1: {
        title: "Integrated Payments",
        description:
          "Process ticket payments securely within tessera’s platform. tessera teams up Surfboard Payments to provide a seamless payment experience for your attendees.",
        description_2:
          "Onlines checkouts have never been easier. Pay with either credit/debit card or Swish. tessera provides a secure payment experience for your attendees.",
        description_3: "Read more about Surfboard Payments",
      },
      section_2: {
        title: "Because it's secure",
        description:
          "tessera’s platform ensures that all payments are processed securely, with robust refund management to make handling payments stress-free.",
        description_2:
          "With tessera, you can manage refunds directly from the platform, simplifying event management and providing a seamless experience for attendees.",
      },
    },
    custom_event_forms: {
      title: "<1>Custom</1> Event Forms",
      description:
        "Collect custom attendee information to personalize your event experience",
      section_1: {
        title: "Collect Custom Information",
        description:
          "tessera's customizable form builder allows you to collect attendee information specific to your event. Whether it's questions or special requests, tessera helps you gather the right information. This information is stored securely and can be accessed at any time.",
        description_2:
          "Our easy-to-use form builder lets you collect information of different types, including text, numbers, multiple choice, with the possibility to add custom fields. Let us help you create a successful event.",
      },
      section_2: {
        title: "Because it's flexible",
        description:
          "tessera provides a flexible data management system, securely storing all the information you collect. With privacy and data security in mind, tessera ensures that all attendee data is handled securely.",
        description_2:
          "tessera's platform is GDPR-compliant, ensuring that your event and your attendees' data are protected according to the highest standards.",
      },
    },
    privacy_security: {
      title: "Privacy and Data <1>Security</1>",
      description:
        "Protecting user data with GDPR compliance and robust security measures",
      section_1: {
        title: "GDPR Compliance",
        description:
          "tessera is committed to safeguarding user data in line with GDPR regulations. We ensure that all personal information is handled transparently and securely, giving your attendees peace of mind.",
        description_2:
          "Our platform provides clear privacy policies that inform users about how their data is used, stored, and protected.",
      },
      section_2: {
        title: "Because it's secure",
        description:
          "tessera employs robust security measures to ensure that all attendee information is kept safe. We utilize encryption and secure data handling practices to protect against unauthorized access.",
        description_2:
          "With tessera, you can focus on creating amazing events, knowing that your attendees' data is secure and their privacy is respected.",
      },
    },
    manager_onboarding: {
      title: "Manager <1>Onboarding</1>",
      description: "Get Started Quickly with Easy Onboarding and Setup",
      section_1: {
        title: "Easy Onboarding",
        description:
          "tessera provides a simple onboarding process for event managers. Create an account, set up your organization, fill in your business details, and start selling tickets in no time.",
        description_2:
          "The onboarding verifications process takes 1-4 days. Once your account is verified, you can start creating events and managing ticket releases.",
      },
      section_2: {
        title: "Because it's necessary",
        description:
          "tessera ensures that all of its managers are legitimate businesses. This helps to protect attendees and ensure that events are run professionally.",
        description_2:
          "Signup today and register your company today to start creating events for your attendees. tessera's onboarding is easy to follow and will have you up and running in no time.",
        description_3_logged_out:
          "Lets start by creating an account and registering your company!.",
        description_3_logged_in:
          "If you haven't already selected a plan, you can do so now. Once you have selected a plan, the onboarding process will guide you through the following steps!",
        steps: {
          step_1: {
            number: "01",
            label: "Sign up",
          },
          step_2: {
            number: "02",
            label: "Register company",
          },
          step_3: {
            number: "03",
            label: "Fill out details",
          },
          step_4: {
            number: "04",
            label: "Done!",
          },
        },
      },
    },
  },

  main_page: {
    phrases: {
      "1": "Ticket releases shouldn't be a pain!",
      "2": "Ticket releases shouldn't be tricky!",
      "3": "Ticket releases shouldn't be a hassle!",
      "4": "Event management is now easy!",
      "5": "Event management is now simple!",
      "6": "Event management is now straightforward!",
    },
    key_features: {
      title: "Why tessera?",
      feature1: {
        title: "Innovative Ticket Distribution",
        description:
          "tessera offers flexible ticketing options, including lotteries and first-come-first-served methods. This ensures a fair and transparent distribution, no matter the event size.",
      },
      feature2: {
        title: "Effortless Event Management",
        description:
          "Creating and managing events has never been easier. With tessera’s streamlined system, you can set up events, release tickets, and handle attendees in just a few clicks.",
      },
      feature3: {
        title: "Fully Customizable Event Pages",
        description:
          "Create beautiful, branded event pages with tessera’s customization tools. Adjust colors, logos, and layouts to make your event stand out, reflecting your unique identity.",
      },
    },
    social_proof: {
      title: "What Our Customers Say",
      cta: "Sign Up Now!",
      ready: "Are you ready to get started?",
      description:
        "Sign up today and start using our free plan, or book a demo to learn more about our premium plans.",
    },
    not_a_pain: "Ticket releases shouldn't be a pain!",
    welcome: "Welcome to tessera, {{name}}",
    learn_how_button: "Learn How",
    get_in_touch_button: "Get in Touch",
    login_page: {
      secure_spot: "Secure your spot.",
      for_business_inquiries:
        "For business inquiries, please contact <1>Lucas Dow</1>, the creator of tessera.",
    },
    page_description: {
      what_title: "What is tessera?",
      what: "tessera is a platform that simplifies ticket releases. Purchasing tickets should not be a hassle. tessera makes it easy to create and manage ticket releases. Event organizers can easily view preferences and allergies to enhance your experience, without any extra effort. Your account is automatically linked to your tickets, eliminating the need for additional forms.",
      in_beta_title: "tessera BETA",
      in_beta:
        "tessera is currently in BETA, meaning it is still being developed and improved. If your organization is interested in using tessera BETA, we will set up a team for you free of charge. This allows you to create and manage events while providing valuable feedback to help us improve tessera. Contact us to get started!",
      how_title: "How Does It Work?",
      how: "To create a ticket release for your event, you must first become an event organizer by creating a team. Currently, teams are created by contacting us. Once your team is set up, you can start creating and managing ticket releases, and invite other members to help. Users can view public events under the 'Events' tab. tessera offers various ticket distribution methods like lotteries and first-come-first-served systems. Users request tickets, and once the release closes, tickets are allocated based on the chosen method. For questions, feel free to contact us.",
    },
    get_in_touch: {
      call_to_action: "Get in Touch",
      quote1:
        "I was so frustrated with always using google forms for ticket releases, so I decided to create tessera to make it easier for everyone.",
      quote2:
        "tessera is the biggest project I've ever worked on, and I'm so excited to see it grow. If you are interested in using tessera, please get in touch.",
    },
    how_to_use: {
      request_ticket_title: "Request a Ticket",
      request_ticket:
        "tessera's innovative ticket distribution allows users to request tickets through methods like lotteries or first-come-first-served, making the process fairer and more accessible. Go to the 'Events' tab, find your event, and click 'Request' to begin.",
      fill_out_title: "Fill Out Personal Details",
      fill_out:
        "After requesting a ticket, provide personal details like food preferences and allergies. This helps organizers tailor the event to your needs, ensuring a personalized experience. tessera links your account to your tickets, streamlining the process and enhancing your event experience.",
      wait_title: "Wait for Ticket Allocation",
      wait: "After requesting a ticket, wait for the ticket release to close and for tickets to be allocated by lottery or first-come-first-served basis. tessera ensures fair distribution, so monitor your email or tessera account for updates. If successful, you'll receive further instructions, including payment details if necessary.",
    },
  },
  tooltips: {
    add_ticket_type: "Add Ticket Batch",
    manage_ticket_releases: "Manage ticket releases for this event.",
    manage_tickets:
      "This table shows all the ticket requests and tickets for this event.",
    manage_tickets_custom_event_form_description:
      "Here you can see all the form responses for this event. This information was submitted by users when requesting tickets.",
    must_be_edited: "This form must be valid before proceeding.",
  },
  form: {
    required_description: "Fields marked with * are required.",
    event_details: {
      name: "Name",
      name_helperText: "What is the name of your event?",
      description: "Description",
      description_helperText:
        "Describe your event. What should people expect? Markdown is supported.",
      date: "Date",
      date_helperText: "When is your event?",
      end_date: "End Date",
      end_date_helperText: "When does your event end?",
      location: "Location",
      location_helperText: "Where is your event?",
      common_locations: "Commonly Used Locations",
      team: "Team",
      team_helperText:
        "Which team is hosting your event? You need to tie the event to a team. If you're not part of a team, you can create one.",
      private_event: "Private Event",
      private_event_helperText:
        "Is your event private? Only people with the link can see it.",
      collect_food_preferences: "Collect Food Preferences",
      collect_food_preferences_helperText:
        "Do you want to collect food preferences from users?",
    },
    ticket_release: {
      name: "Name",
      name_helperText: "What is the name of your ticket release?",
      description: "Description",
      description_helperText:
        "Describe the types of tickets being released. What should people expect? Markdown is supported.",
      available_at: "Available At",
      available_at_helperText:
        "When will the tickets be released? This is when users can start requesting tickets.",
      closes_at: "Closes At",
      closes_at_helperText:
        "When will the ticket release close? This is when users can no longer request tickets.",
      ticket_release_method: "Ticket Release Method",
      ticket_release_method_helperText:
        "How do you want to distribute tickets?",
      lottery_duration: "Lottery Duration (minutes)",
      lottery_duration_helperText:
        "For First Come First Serve, the lottery duration defines the time frame within which requested tickets will be entered into a lottery. If more tickets are requested than available, all requests within this time frame will enter a lottery, the rest will be reserves.",
      max_tickets_per_user: "Max Tickets per User",
      max_tickets_per_user_helperText: "How many tickets can a user request?",
      tickets_available: "Tickets Available",
      tickets_available_helperText: "How many tickets will be available?",
      notification_method: "Notification Method",
      notification_method_helperText:
        "How do you want to notify users about the ticket release?",
      cancellation_policy: "Cancellation Policy",
      cancellation_policy_helperText: "What is your cancellation policy?",
      reserved_ticket_release: "Reserved Ticket Release",
      reserved_ticket_release_helperText:
        "A reserved ticket release contains tickets reserved for specific users. A promo code is required to access these tickets. You can add more ticket releases later.",
      promo_code: "Promo Code",
      promo_code_helperText:
        "What promo code should be used to access this reserved ticket release?",

      selective_description: "Describe the Allocation Method",
      selective_description_helperText:
        "Specify how your team plans to allocate tickets. This information will be shown to users when they request a ticket.",
      save_template: "Save as Template",
      save_template_helperText:
        "Save this ticket release as a template to use again later.",

      payment_deadline: "Payment Deadline",
      payment_deadline_helperText:
        "When is the payment deadline for users who initially got a ticket?",
      reserve_payment_duration: "Reserve Payment Duration",
      reserve_payment_duration_helperText:
        "When a reserve receives a ticket, how long do they have to pay before it's given to the next person in line?",
      allocation_cut_off: "Allocation Cut-off",
      allocation_cut_off_helperText:
        "When will the allocation of tickets be cut off? This is when the system will stop allocating tickets.",
    },
    ticket_types: {
      name: "Name",
      name_helperText: "What is the name of this ticket?",
      description: "Description",
      description_helperText:
        "Describe what's included in this ticket. Markdown is supported.",
      price: "Price (SEK)",
      price_helperText: "How much does this ticket cost?",
      save_template: "Save as Template",
      save_template_helperText:
        "Save this ticket as a template to use again later.",
    },
    contact: {
      title: "Contact",
      email: "Your Email",
      plan: "Plan",
      email_helperText: "What is the contact email for this ticket?",
      subject: "Subject",
      subject_helperText: "What is the subject for this ticket?",
      name: "Your Name",
      name_helperText: "What is the name of the contact person?",
      message: "Message",
      message_helperText: "What is the message for this ticket?",
      success: "Your message has been sent!",
      fail: "Your message could not be sent. Please try again later.",
      description:
        "If you have any questions, problems, or suggestions, feel free to contact us. We will get back to you as soon as possible.",
      team_name: "Team",
      team_helperText: "Which team are you contacting?",
    },

    event_fields: {
      title: "Custom Event Fields",
      subtitle:
        "Add and edit custom fields for your event. These fields will be shown to users when they request a ticket.",
      label_name: "Name",
      label_description: "Description",
      label_type: "Type",
      label_required: "Required",
      form_field_description: "Description",
      form_field_description_helperText:
        "Describe what additional information you want to collect from users, and why. Markdown is supported.",
      delete_field_confirm:
        "Are you sure you want to delete this field? Deleting this field might result in loss of response data. You must save after deleting!",
      select_field_type: "Select a field type",
      field_name: "Field Name",
      field_name_placeholder: "Enter field name",
      field_description: "Field Description",
      field_description_placeholder: "Enter field description",
      required: "Required",
      options_label: "Options",
      option: "Option",
      option_placeholder: "Enter option",
      button_add_option: "Add Option",
      button_delete_option: "Delete Option",
      delete_field: "Delete Field",
    },

    addon: {
      name: "Name",
      name_helperText: "What is the name of this addon?",
      description: "Description",
      description_helperText: "Describe this addon. Markdown is supported.",
      price: "Price (SEK)",
      price_helperText: "How much does this addon cost?",
      max_quantity: "Max Quantity",
      max_quantity_helperText: "How many of this addon can a user buy?",
      is_enabled: "Enabled",
      is_enabled_helperText: "An enabled addon is available for purchase.",
      contains_alcohol: "Contains Alcohol",
      contains_alcohol_helperText: "Does this addon involve alcohol?",
    },

    banking_details: {
      vat: "VAT (%)",
      vat_helperText: "What is the VAT percentage for this event?",
    },

    domain_details: {
      slug: "Subdomain",
      slug_helperText: "What is the subdomain for this event?",
    },

    manager: {
      setup: {
        business_details: {
          legal_name: "Legal Name",
          legal_name_helperText: "Legal name of your business.",
          corporate_id: "Corporate ID",
          corporate_id_helperText: "Corporate ID of your business.",
          business_email: "Business Email",
          business_email_helperText: "Email of your business.",
          country: "Country",
          country_helperText: "Country of your business.",
          phone_number: "Phone Number",
          phone_number_helperText: "Phone number of your business.",
          address_line1: "Address Line 1",
          address_line1_helperText: "Address Line 1 of your business.",
          address_line2: "Address Line 2",
          address_line2_helperText: "Address Line 2 of your business.",
          city: "City",
          city_helperText: "City of your business.",
          postal_code: "Postal Code",
          postal_code_helperText: "Postal code of your business.",
          terms_and_conditions_url: "Terms and Conditions URL",
          terms_and_conditions_url_helperText:
            "URL to your business's terms and conditions.",
          privacy_policy_url: "Privacy Policy URL",
          privacy_policy_url_helperText:
            "URL to your business's privacy policy.",
        },
      },
    },

    organization: {
      store_customization: {
        title: "Store Customization",
        description:
          "Customize the look and feel of your organization's store. This will be visible when users pay for tickets organized by your team.",
        background_color: "Background Color",
        background_color_helperText:
          "Choose a background color for your store.",
        brand_color: "Brand Color",
        brand_color_helperText: "Choose a brand color for your store.",
        accent_color: "Accent Color",
        accent_color_helperText: "Choose an accent color for your store.",
        rect_shape: "Rectangle Shape",
        rect_shape_helperText: "Choose the shape of your buttons.",
        rect_shapes: {
          rounded: "Rounded",
          edgy: "Edgy",
          pill: "Pill",
        },
        font_type: "Font Type",
        font_type_helperText: "Choose the font type for your store.",
        font_types: {
          sans_serif: "Sans Serif",
          serif: "Serif",
          mono: "Monospace",
        },
        logo_url: "Logo URL",
        logo_url_helperText: "Enter the URL of your organization's logo.",
        icon_url: "Icon URL",
        icon_url_helperText: "Enter the URL of your organization's icon.",
      },
    },

    button_sign_in: "Sign in",
    button_add_field: "Add Field",
    button_clear: "Clear",
    button_next: "Next",
    button_edit: "Edit",
    button_create: "Create",
    button_back: "Back",
    button_restart: "Restart",
    button_confirm: "Confirm",
    button_cancel: "Cancel",
    button_create_event: "Create Event",
    button_save: "Save",
    button_submit: "Submit",
    button_invite: "Invite",
    button_manage: "Manage",
    button_details: "Details",
    button_request: "Request",
    button_purchase: "Purchase",
    button_delete: "Delete",
    button_send: "Send",
    button_check_in: "Check in",
    button_update_gdpr: "Save and Renew Consent",
    button_send_out: "Send Out",
    button_economy: "Economy",
    generate_sales_report: "Generate Sales Report",
  },

  create_event: {
    title: "Create Event",
    create_event_description:
      "Create an event to manage ticket releases, attendees, and more. tessera makes it as easy as possible. We will walk you through the process step by step.",
    event_details_title: "Event Details",
    event_details_description:
      "Let's start with the basics. What are the details of your event?",
    ticket_release_title: "Create Ticket Release",
    ticket_release_description:
      "A ticket releases contains a group of ticket batches. Here you can specify when a batch of tickets will be available for people to request. You also define how tickets will be distributed.",
    ticket_release_description_example:
      "For example, you can create a ticket release for early bird tickets, with Regular and VIP tickets that requires a promo code. Then you can create another ticket release for regular tickets, with its own ticket batches.",
    ticket_release: "Ticket Release",
    ticket_release_helperText:
      "Let's define the details for this ticket release.",
    confirm_event_creation_restart_text:
      "Are you sure you want to restart event creation? All progress will be lost.",
    ticket_types_title: "Ticket Batches",
    ticket_types_description:
      "Define the different types of tickets available for your event. Each ticket release can have multiple ticket batches. You can create more ticket types later on the edit event page. The total amount of tickets will be distributed among the ticket batches. The tickets you create here will automatically be apart of the ticket release you previously defined.",
    ticket_types: "Ticket Batches",
    ticket_types_helperText:
      "Here you can specify the details of your different ticket batches.",
    finish_title: "That's it!",
    finish_description:
      "You have successfully filled out all the details for your event. Click the button below to create your event. You can also go back and edit your event, or do so later on the edit event page.",
    no_teams:
      "You are currently not part of any team, and therefore cannot create an event. Contact your organization's administrator to create a team.",
    review_submit_title: "That's it!",
  },

  templates: {
    title: "Saved Templates",
    ticket_releases: {
      description:
        "You can create new templates by ticking the 'Save as Template' checkbox when creating a ticket release. Here you can see all your saved templates. Click 'Create' to create a new event from a template.",
      no_templates: "There are no templates available.",
    },
    ticket_types: {
      description:
        "Templates for ticket batches are a bit different from ticket releases. Saving a template will assume that you will keep that template for the ticket release you are editing. Editing your template will automatically update all ticket releases that use that template.",
      no_templates: "There are no templates available.",
    },
  },

  profile: {
    title: "Profile",
    user_info_title: "Profile",
    full_name: "Full Name",
    email: "Email",
    username: "Username",
    roles: "Roles",
    teams: "Teams",

    tabs: {
      info: "Profile",
      tickets: "Your Tickets",
    },

    links_and_buttons: {
      your_ticket_requests: "My Ticket Requests",
      your_tickets: "My Tickets",
      your_teams: "My Teams",
    },

    food_preferences: {
      title: "Food Preferences",
      saved: "Your food preferences have been stored.",
      allergies_and_dietary_restrictions: "Allergies and Dietary Preferences",
      allergies_and_dietary_restrictions_select:
        "Allergies and Dietary Preferences (Select all that apply)",
      allergies_and_dietary_restrictions_helperText:
        "Select all that apply, leave blank if none.",
      additional_notes: "Additional Notes",
      additional_notes_helperText:
        "Provide any additional notes about your food preferences. Leave blank if none.",
      privacy_policy_title: "Privacy Policy",
      gdpr_agree_helperText:
        "I agree to the processing of my personal data for managing my food preferences according to the <1>Privacy Policy</1>.",
      no_preferences: "You have no food preferences.",
      select_placeholder: "Select...",
    },

    your_ticket_requests: {
      title: "My Ticket Requests",
      description:
        "Listed below are all the events you currently have a ticket for. Click on a ticket to see more details.",
      no_upcoming_events: "You have no upcoming events.",
      past_events: "Past Events",
      no_past_events: "You have no past events.",
      no_ticket_requests: "You have no ticket requests.",
      upcoming_events: "Upcoming Events",
      show_past_events: "Show",
      hide_past_events: "Hide",
    },

    your_tickets: {
      title: "My Tickets",
      description:
        "Here you can see all the tickets you have received. You can give up your ticket by clicking on it and choosing 'I no longer wish to attend', which will pass your ticket to the next person in line. If you have not yet been allocated a ticket, you can see your ticket requests <1>here</1>.",
      upcoming_events: "Upcoming Events",
      no_upcoming_events: "You have no upcoming events.",
      past_events: "Past Events",
      no_past_events: "You have no past events.",
      no_tickets: "You have no tickets.",
      order_id: "Order ID",
      show_deleted: "Show Deleted",
    },

    your_teams: {
      title: "Your Teams",
      description:
        "Here you can see all the teams you are a part of. Click on a team to see more details. You can create a new team <1>here</1>.",
      not_part_of_any_teams: "No teams to list.",
      add_user: "Add User",
      add_user_helperText:
        "Enter the email of the user you want to add to this team. You can change their role later.",
      manage_team_events: "Manage Team Events",
      no_events:
        "There are no events in this team. Create one now <1>here</1>.",
      users: "Users",
      no_users: "There are no users in this team.",
      delete_team: "Delete Team",
      team_name_title: "Team Name",
      edit_team: "Edit Team",
      delete_team_confirmation_title: "Confirm Team Deletion",
      delete_team_confirmation:
        "Are you sure you want to delete this team? This action cannot be undone.",
    },
  },

  create_team: {
    title: "Create Team",
    what_is_a_team: "What is a Team?",
    description:
      "Create a team to organize events and manage users. Team managers can create events, manage ticket sales, and plan events efficiently. You can add users to your team and assign different permissions. You can create and join multiple teams within a network and leave teams at any time. Without a team, you cannot create events.",
    teams_created_by_contacting_us:
      "Currently, teams are created by contacting us. We are working on a solution to allow users to create teams themselves.",
    your_teams_text: "Your Teams",
    add_team_title: "Team Name",
    add_team_helperText:
      "This will be the name of your team. You will automatically be the owner of this team.",
    create_team_button: "Create Team",
    add_team_email: "Team Email",
    add_team_email_helperText:
      "This will be the email of your team. It is used for contacting the team.",
  },

  manager: {
    dashboard: {
      events: "Events",
      events_description: "Here you can see all the events in your network.",
      no_events: "There are no events to show.",
      no_events_create: "Create your first event",
    },

    setup: {
      title: "Setup",
      description: "Complete your profile to start creating events.",
    },

    onboarding: {
      welcomeMessage: "Welcome to tesseras Event Manager Setup & Onboarding!",
      intro:
        "Welcome to the onboarding setup page for event managers. As an event organizer, you are just a few steps away from setting up your payment processing system through Surfboard Payments. Please follow the instructions below to provide the necessary details and complete the onboarding process.",
      stepByStepGuide: "Step-by-Step Guide to Complete Onboarding",
      steps: {
        step1: {
          title: "Fill in Business Details",
          description:
            "Please provide the necessary business details in the form below. This information will be used to create your merchant account and online store.",
        },
        step2: {
          title: "Fill out KYB Form",
          active: "Click the link below to fill out the KYB form.",
          description:
            "tessera will generate a Know Your Business (KYB) link for you from the Surfboard Payments partner portal. This link will be sent to your registered email address.",
        },
        step3: {
          title: "Verification Process",
          description:
            "Once you submit your details, Surfboard Payments will verify the legitimacy of your business. This process typically takes 1-4 business days. You will receive a notification via email once your business details have been approved.",
        },
        step4: {
          title: "Account Creation",
          description:
            "After approval, Surfboard Payments will automatically create a merchant account and an online store for your business. You will receive the credentials and relevant information for accessing your merchant account.",
        },
      },
      customization: {
        title: "Customization",
        description:
          "The onboarding web-app can be customized to match the branding of your business. Please provide the necessary details below.",
      },
      importantLinks: {
        title: "Important Links",
        description:
          "If you want to learn more about Surfboard Payments, please visit the following links:",
        links: {
          partnersGuideTitle: "Partners Guide",
          partnersGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/partners",

          merchantsGuideTitle: "Merchants Guide",
          merchantsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/merchants/merchants-home",

          storesGuideTitle: "Stores Guide",
          storesGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/stores/stores-home",

          terminalsGuideTitle: "Terminals Guide",
          terminalsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/terminals/terminals-home",

          onboardingRequirementsTitle: "Onboarding Requirements",
          onboardingRequirementsURL:
            "https://developers.surfboardpayments.com/docs/concepts/onboarding#requirements-for-onboarding",

          onlinePaymentsGuideTitle: "Online Payments Guide",
          onlinePaymentsGuideURL:
            "https://developers.surfboardpayments.com/docs/guides/online-payments",
        },
      },
      needHelp: {
        title: "Need Help?",
        description:
          "If you have any questions or need assistance during the onboarding process, please contact us at <1>info@tesseratickets.se</1>.",
      },
      thankYou: "Thank you for choosing tessera!",
    },

    settings: {
      general: {
        title: "Dashboard Color Settings",
        description:
          "Totally customize the look of your dashboard and make it truly yours. Choose your main color, accent color, and text color. Note that these colours will also be used for your default event pages.",
        main_color: "Main Color",
        accent_color: "Accent Color",
        text_color: "Text Color",
        pick_color: "Pick {{color}}",
        close_color_picker: "Close {{color}} Picker",
      },
      users: {
        title: "Network Users",
        description:
          "Here you can invite users to your network by sending them an email invitation. You can also manage the roles of users in your network. Users can be assigned the roles of Super Admin, Admin, or Member. When a user accepts an invitation, they will be able to access the network and can be added to the teams within the network to start creating events.",
        no_users: "There are no users in this network.",
        delete_network: "Delete Network",
        delete_network_confirmation:
          "Are you sure you want to delete this network? This action cannot be undone.",
        delete_network_confirmation_title: "Confirm Network Deletion",
        edit_network: "Edit Network",
        invite_user: "Invite User",
        invite_user_helperText:
          "Enter the email of the user you want to invite.",
        user_added_success: "User added successfully!",
        select_role_placeholder: "Select role",
        role_super_admin: "Super Admin",
        role_admin: "Admin",
        role_member: "Member",
        invite_pending: "Invite Pending",
        no_pending_invites: "There are no pending invites.",
      },
      subscription: {
        title: "Subscription",
        description:
          "Manage your subscription plan. You can upgrade your plan to get more features.",
        current_plan: "Current Plan",
        upgrade_plan: "Upgrade Plan",
        upgrade_plan_description: "In BETA, upgrading plans is not available.",
        downgrade_plan: "Downgrade Plan",
        cancel_plan: "Cancel Plan",
        cancel_plan_confirmation:
          "Are you sure you want to cancel your plan? This action cannot be undone.",
        cancel_plan_confirmation_title: "Confirm Plan Cancellation",
        payment_plan: "Payment Plan",
        enabled_features: "Enabled Features",
        enabled_features_description:
          "In BETA, all features are enabled, here is a list of all the features you can use.",
      },
      customization: {
        title: "Merchant Customization",
        description:
          "Customize the look of your merchant portal. Make yourself unique and apply your brand colors and logo.",
        background_color: "Background Color",
        background_color_helperText:
          "Choose a background color for your network.",
        brand_color: "Brand Color",
        brand_color_helperText: "Choose a brand color for your network.",
        accent_color: "Accent Color",
        accent_color_helperText: "Choose an accent color for your network.",
        rect_shape: "Rectangle Shape",
        rect_shape_helperText: "Choose the shape of your buttons.",
        upload_logo: "Upload Logo",
        logo_helperText:
          "The file can not be bigger than 3000x3000 pixels and exceed 2MB.",
        upload_icon: "Upload Icon",
        rect_shapes: {
          rounded: "Rounded",
          edgy: "Edgy",
          pill: "Pill",
        },
        font_type: "Font Type",
        font_type_helperText: "Choose the font type for your network.",
        font_types: {
          sans_serif: "Sans Serif",
          serif: "Serif",
          mono: "Monospace",
        },
        logo_url: "Logo URL",
        logo_url_helperText: "Enter the URL of your network's logo.",
        icon_url: "Icon URL",
        icon_url_helperText: "Enter the URL of your network's icon.",
        save_settings: "Save Settings",
        reset_settings: "Reset Settings",
        save_logo: "Save Logo",
      },
    },

    teams: {
      title: "{{name}} Teams",
      subtitle:
        "A team is the organizer of event. A network consists of multiple teams that can create events. A network admin can create and add users to teams. Below you can see all the teams in your network, click on a team to see more details.",
    },

    drawer: {
      title: "Network Dashboard",
      events: "Events",
      merchant_portal: "Merchant Portal",
      all_events: "All Events",
      teams: "Teams",
      create_event: "Create Event",
      settings: {
        title: "Settings",
        general: "Dashboard Colors",
        subscription: "Subscription",
        financial: "Financial",
        users: "Users",
        customize: "Merchant Customization",
      },
    },
  },

  features: {
    limit_description:
      "You have exceeded the limit of {{feature}}. Upgrade your plan to get more.",
  },

  manage_event: {
    title: "Manage {{event_name}}",
    delete_button: "Delete {{event_name}}",
    view_button: "View Event",
    manage_button: "Manage",
    manage_ticket_releases: "Manage Ticket Releases",
    ticket_release_method_title: "Ticket Release Method",
    ticket_release_ticket_info_title: "Ticket Info",
    ticket_orders: "Ticket Orders",
    ticket: "Tickets",
    lottery_entered_ticket_requests: "Lottery Entered Ticket Requests",
    not_lottery_entered_ticket_requests: "Requested After Lottery Window",
    paid_tickets: "Paid Tickets",
    not_yet_paid_tickets: "Not Yet Paid Tickets",
    refunded_tickets: "Refunded Tickets",
    reserve_tickets: "Reserve Tickets",
    ticket_release_actions_title: "Ticket Release Actions",
    closed: "has closed",
    open: "is open",
    allocate_tickets_button: "Allocate Tickets",
    the_ticket_release: "The ticket release",
    deleted_ticket_requests: "Deleted Ticket Requests",
    remaining_tickets: "Remaining Tickets",
    deleted_tickets: "Deleted Tickets",
    not_open: "Not Open",
    not_yet_open: "Not Yet Open",
    check_allocated_reserve_tickets: "Try to Allocate Reserve Tickets",
    check_allocated_reserve_tickets_tooltip:
      "Press this button to manually allocate reserve tickets. Useful if you don't want to wait for automatic allocation.",
    allocated_tickets: "Allocated Tickets",
    pay_within_hours: "Users must pay within (hours)*",
    edit_payment_deadline: "Edit Payment Deadline",
    payment_deadline: "Payment Deadline",
    payment_deadline_description:
      "Specify the payment deadline for your event. This does not affect already allocated tickets. Only tickets allocated after this change will be affected.",
    payment_deadline_not_editable:
      "Payment deadline is not editable for this ticket release. The payment deadline is set when tickets are allocated.",
    payment_deadline_helperText:
      "When is the payment deadline for users who initially got a ticket?",
    reserve_payment_duration: "Reserve Payment Duration",
    reserve_payment_duration_helperText:
      "When a reserve receives a ticket, how long do they have to pay before it's given to the next person in line?",
    reserve_payment_duration_text:
      "Allocated reserve tickets have a default payment deadline of {{days}} days, {{hours}} hours, {{minutes}} minutes, and {{seconds}} seconds.",
    allocate_tickets_confirm_title: "Confirm Ticket Allocation",
    allocate_tickets_warning:
      "This ticket release is currently open. Allocating tickets now will automatically close the release. Are you sure you want to allocate tickets now?",
    allocate_tickets_confirm:
      "Are you sure you want to close this ticket release?",
    manage_tickets: "Manage Tickets",
    tickets_available: "Tickets Available",
    allocate_tickets_helperText:
      "How long do users have to pay for their tickets before they're given to the next person in line?",
    delete_event_title: "Confirm Event Deletion",
    delete_event_confirmation:
      "Are you sure you want to delete this event? This action cannot be undone.",
    delete_event_confirmation_enter_text: "Type 'delete' to confirm deletion.",
    delete_ticket_release_confirmation:
      "Are you sure you want to delete this ticket release? This action cannot be undone.",
    manage_tickets_custom_event_form: "Form Responses",
    add_ticket_release: "Add Ticket Release",
    ticket_release_time_title: "Ticket Release Open and Close",

    breadcrumbs: {
      manage: "Manage",
      edit: "Edit",
      ticket_releases: "Ticket Releases",
      event: "Event",
      add_ons: "Addons",
      ticket_types: "Ticket Batches",
      form: "Form",
      send_outs: "Send Outs",
      tickets: "Tickets",
      event_page: "Event Page",
      emails: "Emails",
    },

    overview: {
      title: "Overview",
      site_visits: "Site Visits",
      unique_visitors: "Unique Visitors",
      num_ticket_orders: "Ticket Orders",
      total_income: "Revenue",
      total_ticket_orders: "Total Ticket Orders",
      total_paid_tickets: "Total Paid Tickets",
      total_refunded_tickets: "Total Refunded Tickets",
      total_revenue: "Total Revenue",
      total_remaining_tickets: "Total Remaining Tickets",
      revenue_per_ticket: "Revenue per Ticket",
      event_capacity: "Event Capacity",
      abandoned_orders: "Abandoned Orders",
      time_to_payment_avg: "Time to Payment (avg)",
      event_is_at_capacity: "Event is at capacity",
      tickets_remaining: "tickets remaining",
      from_creation_to_payment: "From creation to payment",
      total_payable_tickets: "Total Payable Tickets",
    },
    no_data: "No data available.",

    ticket_releases: {
      title: "Manage Ticket Releases",
    },

    settings: {
      financial: {
        title: "Financial Settings",
        description:
          "Manage the financial settings for your event. Add your banking details and view the financial status of your event.",
      },

      domain: {
        title: "Domain Settings",
        description: "Manage the domain settings for your event,",
      },
    },

    drawer: {
      title: "Event Dashboard",
      settings: {
        title: "Settings",
        financial: "Financial",
        emails: "Emails",
        domains: "Domains",
      },

      edit: {
        event: "Edit Event",
        ticket_releases: "Edit Ticket Releases",
        ticket_types: "Edit Ticket Batches",
        form: "Edit Form",
        event_page: "Edit Event Page",
      },

      send_outs: {
        title: "Send Outs",
        list: "List",
        new: "New",
      },

      emails: {
        title: "Emails",
        templates: "Templates",
      },

      manage: {
        title: "Management",
        overview: "Overview",
        tickets: "Tickets",
        ticket_releases: "Ticket Releases",
        check_in: "Check In",
        form_responses: "Form Responses",
      },

      economy: {
        title: "Economy",
        sales_report: "Sales Report",
        pay_outs: "Pay Outs",
      },

      is_not_pinned: "Pin Menu",
      is_pinned: "Unpin Menu",
    },

    tickets: {
      title: "Manage Tickets",

      user: {
        info: "User Info",
        food_preferences: "Food Preferences",
        full_name: "Full Name",
        username: "Username",
        id: "ID",
        email: "Email",
        phone: "Phone",
        is_guest: "Is Guest",
      },

      ticket_info: {
        id: "ID",
        title: "Ticket Info",
        ticket_batch: "Ticket Batch",
        ticket_release: "Ticket Release",
        requested_at: "Requested At",
        is_paid: "Is Paid",
        allocated: "Allocated",
        purchasable_at: "Purchasable At",
        payment_deadline: "Payment Deadline",
        entered_into_lottery: "Entered into Lottery",
        is_reserve: "Is Reserve",
        paid_at: "Paid At",
        checked_in: "Checked In",
        deleted_at: "Deleted At",
        is_deleted: "Is Deleted",
        deleted_reason: "Reason",
        ticket_type: "Ticket Type",

        ticket_types: {
          ticket: "Ticket",
          ticket_request: "Ticket Request",
        },

        add_ons: {
          title: "Addons",
          name: "Name",
          price: "Price",
          quantity: "Quantity",
          contains_alcohol: "Contains Alcohol",
        },

        edit: {
          title: "Edit Ticket Info",
          checked_in_helperText: "Has the user checked in?",
          payment_deadline_helperText:
            "The user will receive an email with the updated payment deadline.",
        },
      },

      event_form: {
        title: "Form Responses",
      },

      ticket_actions: {
        title: "Ticket Actions",
        allocate: "Allocate",
      },

      payment_info: {
        title: "Payment Info",
        currency: "Currency",
        amount: "Amount",
        refunded: "Refunded",
        refunded_at: "Refunded At",
        payment_method: "Payment Method",
      },
    },

    economy: {
      title: "Event Economy",
      subtitle:
        "View the financial details of your event. Generate sales reports and see total revenue.",
      sales_reports: "Sales Reports",
      total_sales: "Total Sales",
      tickets_sold: "Tickets Sold",
      created_at: "Created At",
      status: "Status",
      message: "Message",
      download: "Download",
      no_reports: "There are no sales reports for this event.",
    },

    private_event: {
      title: "This is a Private Event",
      subtitle: "This event is private. Share the link below with invitees.",
    },
    send_out: {
      title: "Send Outs",
      new: "New Send Out",
      description:
        "Send an email to all users who have requested a ticket for this event. Notify users about the ticket release or allocation.",
      preview: "Preview",
      subject: "Subject",
      subject_helperText: "What is the subject of the email?",
      message: "Message",
      message_helperText:
        "What are the contents of the email? Markdown is supported.",
      preview_helperText: "This is how the email will appear to users.",
      ticket_releases: "Select Ticket Releases",
      ticket_releases_helperText:
        "The users of the selected ticket releases will receive the email.",
      filter_tickets: "Filter Tickets",
      filter_tickets_helperText:
        "Filter tickets based on their status. Multiple statuses can be selected.",
      num_users: "The email will be sent to {{numUsers}} users.",

      recipients: "Recipients",
      status_message: "Status Message",
      no_send_outs: "There are no send outs for this event.",
    },
    edit: {
      title: "Edit Event",
      subtitle: "Edit all details of your event here.",
      event_details: {
        title: "Edit Event Details",
      },
      ticket_releases: {
        title: "Edit Ticket Release",
        edit_name: "Edit {{name}}",
        subtitle:
          "Create and edit ticket releases for your event. Create as many as you want.",
        select: "Select a ticket release to edit.",
        add: "Add Ticket Release",
        add_subtitle: "Define the details for this ticket release.",
        closed: "Closed",
        no_ticket_releases: "There are no ticket releases for this event.",
        edit_ticket_types: "Edit Tickets",
        add_helperText: "Select a ticket release to edit.",

        edit_add_ons: "Edit Ticket Addons",
      },

      add_ons: {
        title: "Edit Ticket add_ons",
        subtitle:
          "Add add_ons to the ticket release. Be specific with the name and description. Specify if the addon costs extra.",
        form_title: "Addon Form",
        form_subtitle: "Define your addons here.",
        confirm_delete_title: "Confirm Addon Deletion",
        confirm_delete_text:
          "Are you sure you want to delete this addon? This action cannot be undone.",
      },

      ticket_types: {
        title: "Edit Ticket Batches",
        ticket_details: "Ticket Details",
        ticket_details_helperText:
          "Modify the details of your ticket types and then click 'Save'.",
      },

      event_page: {
        title: "Edit Event Page",
        description:
          "Choose between a default event page or customize it to your liking.",
        enable: "Use Custom Event Page",
        enabled: "Enabled",
        disabled: "Disabled",
        editor_title: "The Editor",
        editor_description:
          "The event page editor is a powerful tool to help you create a custom event page. Add text, images, videos, and more to design the page as you want. We take care of the rest.",
        editor_button: "Go to Editor",
      },
    },

    form_field_responses: {
      list_view: "List View",
      table_view: "Table View",
    },
  },

  ticket_release_method: {
    first_come_first_served_title: "First Come First Served",
    first_come_first_served_description:
      "First Come First Serve Lottery is a ticket release method where people who request a ticket within a specified time frame are entered into a lottery. When tickets are allocated, all requests within this time frame are entered into a lottery, and winners are selected randomly. Winners receive a ticket, and the rest are placed on the waitlist. Anyone requesting a ticket after the specified time frame is placed on the waitlist unless the lottery is not full. If the lottery is not full, remaining tickets are distributed to those on the waitlist in the order they requested.",
  },

  ticket_request: {
    cost_overview: "Cost Overview",
    cancel_ticket_request_button: "Cancel",
    go_to_tickets_button: "Go to Tickets",
    cancel_ticket_request_confirm_title: "Confirm Ticket Request Cancellation",
    handled: "Ticket Request converted to ticket",
    deleted: "Ticket Request has been deleted",
    ticket_request: "Ticket Request",
    cancel_ticket_request_confirm:
      "Are you sure you want to cancel this ticket request? This action cannot be undone.",
  },

  event_form_fields: {
    title: "Event Form",
    description:
      "The event organizer has requested additional information from you. See the information below.",
    accept_terms_and_conditions:
      "By submitting this form, you agree to share the above information with the event organizer to plan the event. The information will be processed in accordance with the Chapter's information processing policy.",
    no_form_fields: "Oops! There is no form for this event.",
    ticket_number: "Ticket {{number}}",
    ticket_number_description: "Enter details for ticket {{number}} below.",
  },

  tickets: {
    cost_overview: "Cost Overview",
    confirmed_ticket:
      "Your ticket has been confirmed! It's now time to pay for your ticket. Click the button below to pay. If you do not pay before <1>{{payBefore}}</1>, your ticket will be given to the next person in line.",
    reserve_ticket:
      "Unfortunately, you were allocated a reserve ticket for this event. You will be notified if a ticket becomes available.",
    has_paid: "You have paid for your ticket!",
    has_refunded: "Ticket has been refunded",
    request_refund:
      "You can request a refund by contacting the event organizer.",
    no_refund: "No refund available",

    cancel_ticket_request_button: "Cancel",
    cancel_ticket_button: "I No Longer Wish to Attend",
    confirm_cancel_ticket_title: "Confirm Ticket Cancellation",
    confirm_cancel_ticket_request_title: "Confirm Ticket Request Cancellation",
    leave_reserve_list_text: "Leave Reserve List",
    reserve_number: "You are number <1>{{number}}</1> on the reserve list.",
    paid_ticket:
      "You have paid for your ticket! We look forward to seeing you at the event. A receipt has been sent to your email.",
    confirm_cancel_reserve_ticket_text:
      "Are you sure you want to cancel your ticket? This action cannot be undone!",
    confirm_cancel_ticket_text:
      "Are you sure you want to cancel this ticket? This action cannot be undone!",
    pay_button: "Pay Now",
    not_paid_on_time:
      "Sorry! You did not pay for your ticket on time, so you lost your ticket.",
    total_cost: "Total",
    payment: {
      title: "Confirm Your Ticket Purchase",
      pay_now: "Pay {{price}} SEK",
      description: "Here you can pay for your ticket.",
    },

    qr_code: {
      description:
        "This is your QR code. Have it ready when you arrive at the event.",
      already_checked_in: "You have already checked in.",
    },
  },

  event: {
    list_title: "Events",
    tickets: "Tickets",
    reserved: "Reserved",
    ticket_releases: "Ticket Releases",
    no_ticket_releases: "There are no ticket releases for this event.",
    event_by: "Event by",
    promo_code_title: "Promo Code",
    promo_code_helperText: "Enter the promo code to access reserved tickets.",
    contact_organizers:
      "If you have any questions, contact <1>{{organization}}</1> <2>here</2>.",
    ticket_request_success_title: "Ticket Request Successful",
    ticket_request_success_description:
      "You can also fill out this information later. Do so <1>here</1>.",
    ticket_order_pay_direct_form_later:
      "You can fill out this information after you have paid for your ticket.",
    continue_pay: "Continue to Payment",
    continue_ticket_orders: "View Ticket Order",
    ticket_releases_description:
      "Listed below are the ticket releases for this event. If you have a promo code, enter it at the bottom.",
    check_in: {
      scan_ticket_title: "Scan Ticket",
      scan_ticket_instructions: "Scan the QR code on the ticket to check in.",
      loading: "Loading...",
      manual_check_in_button: "Check In",
      search_tickets: "Search Tickets",
      checked_in: "Checked In",
      hide_checked_in: "Hide Checked In",
      search_tickets_helperText: "Search for name, email, or ticket ID.",
      ready_to_scan: "Ready to scan",
      no_camera_permission: "No camera permission",
    },
    ticket_release: {
      closed: "Ticket release has closed",
      tickets_available_in: "Tickets available in",
      tickets_available_for: "Ticket release closes in",
      method: "This release uses <1>{{method}}</1>",
      sold_out: "Sold out",
      second: "second(s)",
      minute: "minute(s)",
      hour: "hour(s)",
      day: "day(s)",
      week: "week(s)",
      month: "month(s)",
      method_info: {
        fcfsl:
          "Requests made within the first <1>{{duration}}</1> of the release will be entered into the lottery.",
      },
      reserved: "Reserved",
      no_tickets: "There are no tickets available.",
      remove_reminder: "Remove Reminder",
      set_reminder: "Set Reminder for 10 minutes before release",
      information_processing_policy_info:
        "By requesting/purchasing a ticket, you agree to share your details with the event organiser in accordance with tesseras <1>Pricacy Policy</1>, you also agree to tesseras <2>Refund Policy</2> and <3>Terms & Conditions.</3>",
      checkout: {
        title: "Ticket details",
        overview: "Overview",
        what_is_a_request_title: "What is a Ticket Request?",
        what_is_a_request:
          "When making a request, you are not guaranteed to get the tickets. The allocation is done according to the Ticket Release Method described in the release description.",
        total: "Total",
        ticket: "Ticket",
      },
      add_ons: {
        title: "Ticket Addons",
        description: "Add addons to your ticket.",
        max_quantity: "Max Quantity: {{max}}",
        contains_alcohol: "Contains Alcohol",
        view_add_ons: "View Ticket addons",
        ticket_number: "Ticket {{number}}",
      },
      request_process: {
        complete_ticket_request: "Complete Ticket Request",
        account_required_description:
          "To request a ticket, you must have an account. Sign in or create an account.",
        already_have_an_account: "Already have an account?",
        continue_as_guest: "Continue as Guest",
        form: {
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          phone_number: "Phone Number (optional)",
          password: "Password",
          password_repeat: "Repeat Password",
          button_save_account: "Save Account",
          button_save_account_helperText:
            "We will save your details for future purchases.",
          button_sign_up: "Sign Up",
          button_sign_in: "Sign In",
          button_continue_as_guest: "Continue as Guest",
        },
      },
    },
  },

  customer: {
    login: "Login",
    signup: "Sign Up",
    oauth: "You can also sign in with",
    info: {
      subtitle: "Customer Login and Signup",
      description:
        "Welcome to tessera! By creating an account, your event experience will never be the same. All your tickets collected in one place. Easily request or buy tickets.",
      forgot_password: "Forgot Password?",
      dont_have_an_account: "Don't have an account? Sign up!",
    },
    form: {
      first_name: "First Name",
      last_name: "Last Name",
      username: "Username",
      email: "Email",
      password: "Password",
      password_repeat: "Repeat Password",
      button_signup: "Sign Up",
      button_login: "Login",
      no_account: "Don't have an account? Sign up!",
    },
    accept_terms: {
      first: "I agree to the ",
      privacy_policy: "Privacy Policy",
      terms_of_use: "Terms & Conditions",
      acceptable_use_policy: "Acceptable Use Policy",
      cookie_policy: "Cookie Policy",
    },
    forgot_password: {
      title: "Request New Password",
      description:
        "Enter the email address associated with your account to receive a link to reset your password.",
    },

    wait_verify_email: {
      title: "Verify Your Email",
      description:
        "We have sent you an email with a link to verify your account. Click the link to verify your account.",
    },

    resend: {
      title: "Resend Confirmation Email",
      toast_description: "It seems like you haven't verified your account yet.",
      toast_button: "Resend Email",
      description:
        "If you haven't received an email, you can resend the confirmation email.",
    },
  },

  footer: {
    about_title: "About",
    about_content:
      "tessera is a platform that makes ticket releases and management easy.",
    quick_links_title: "Quick Links",
    home: "Home",
    events: "Events",
    profile: "Profile",
    report_an_issue_title: "Report an Issue",
    made_by: "tessera is built by <1>Lucas Dow</1>",
    report_an_issue_content:
      "If something isn't working or you have a suggestion, <1>Create an issue on GitHub</1>.",
    contact_title: "Contact",
    follow_us: "Follow Us",
    legal_title: "Legal",
  },

  common: {
    back: "Back",
    cancel: "Cancel",
    show_all: "Show All",
    show_less: "Show Less",
    show_more: "Show More",
    search: "Search",
    learn_more: "Learn More",
    created: "Created",
    made_at: "Made At",
    updated: "Updated",
    private_event: "Private Event",
    hour_one: "hour",
    hour_other: "hours",
    minute_one: "minute",
    minute_other: "minutes",
    lets_start: "Let's Start",
    of: "of",
    since: "since",
    days_ago: "days ago",
    mobile_warning:
      "Welcome to tessera! It appears you are using a mobile device. Some website features may not be optimized for mobile. However, requesting and viewing tickets should work as expected. If you're an event organizer, we recommend using a desktop device.",
  },

  refund: {
    dialog_title: "Refund Ticket",
    reason: "Refund Reason",
    amount: "Refund Amount",
    payment_method: "Payment Method",
    submit: "Process Refund",
    ticket_info: "Ticket ID: {{id}}, Type: {{type}}, Price: {{price}}",
    user_info: "User: {{name}}, Email: {{email}}",
    cannot_refund: "Cannot refund ticket",
  },

  error: {
    no_rows_selected: "No rows selected",
    invalid_refund_selection:
      "Invalid refund selection. Please select a single ticket.",
    ticket_not_found: "Selected ticket not found",
    no_ticket_selected: "No ticket selected for refund",
    unknown: "An unknown error occurred",
  },
  success: {
    delete: "Successfully deleted {{type}}",
    undelete: "Successfully undeleted {{type}}",
    allocate: "Successfully allocated tickets",
    refund_processed: "Refund processed successfully",
  },

  pricing: {
    contact_us: "Contact Us",
    get_started: "Get Started",
    selected_plan: "Selected Plan",
    pricing: "Pricing",
    choose_plan: "Choose the plan that works best for you",
    beta_text:
      "tessera is currently in BETA, therefore all features are available on the free plan.",
    how_to_pay: "How do you wish to pay?",
    monthly: "Monthly",
    yearly: "Yearly",
    discount: "33% off",
    excluding_vat: "All prices are excluding VAT.",
    list_of_features: "List of Features",
    per_event: "/event",
    per_month: "/month",
    per_year: "/year",
    most_popular: "Most Popular",
    plans: {
      free: "Free",
    },
    ticket_fees: {
      title: "Ticket Fee",
      description:
        "Ticket fee includes VAT, all banks and credit card fees, no hidden costs, providing you with the services you need to succeed with your event.",
      regular_fees: "Regular Ticket Fees",
      non_profit_fees: "For non-profit organizations",
      fee_1: "3.5 % + 3 SEK",
      fee_2: "3 % + 1 SEK",
      per_sold_ticket: "Per sold ticket",
    },
  },

  faq: {
    search_placeholder: "Search for a question...",
    title: "Frequently Asked Questions",
    ...gbFaq,
  },
};

export default enTranslations;
