// src/components/modals/BookDemoModal.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import InformationModal from "../modal/information";
import { Box, FormControl } from "@mui/joy";
import { Form, Formik } from "formik";
import { FormInput } from "../forms/input_types";
import StyledText from "../text/styled_text";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../forms/form_labels";
import usePalette from "../../theme/use_palette";
import StyledButton from "../buttons/styled_button";
import * as Yup from "yup";
import { StyledErrorMessage } from "../forms/messages";
import { IBookDemoValues } from "../../types";
import { handleBookDemoRequest } from "../../handlers/bookDemoHandler";

const BookDemoSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

interface BookDemoModalProps {
  buttonSize?: "sm" | "md" | "lg";
  fontSize?: number;
}

const BookDemoModal: React.FC<BookDemoModalProps> = ({
  buttonSize,
  fontSize,
}) => {
  const { t } = useTranslation();
  const [bookModalIsOpen, setBookModalIsOpen] = React.useState(false);
  const palette = usePalette();

  const handleSubmit = (values: IBookDemoValues) => {
    handleBookDemoRequest(values);
    // Handle form submission logic here
    setBookModalIsOpen(false);
  };

  const initialValues: IBookDemoValues = {
    name: "",
    phone: "",
    email: "",
  };

  return (
    <div
      style={{
        margin: 0,
      }}
    >
      <StyledButton
        size={buttonSize || "md"}
        onClick={() => setBookModalIsOpen(true)}
        color={palette.primaryMain}
        bgColor={palette.offWhite}
        fs={fontSize || 22}
        sx={{}}
      >
        {t("book_demo.button_text")}
      </StyledButton>
      <InformationModal
        isOpen={bookModalIsOpen}
        onClose={() => setBookModalIsOpen(false)}
        title={t("book_demo.title")}
        width="40%"
      >
        <Box>
          <StyledText
            fontSize={20}
            color={palette.charcoal}
            fontWeight={500}
            level="body-md"
          >
            {t("book_demo.description")}
          </StyledText>
          <Formik
            initialValues={initialValues}
            validationSchema={BookDemoSchema}
            validateOnChange={true}
            validateOnMount={true}
            onSubmit={(values: IBookDemoValues) => {
              handleSubmit(values);
            }}
          >
            {() => (
              <Form
                style={{
                  marginTop: 20,
                }}
              >
                <FormControl>
                  <StyledFormLabel fontSize={18}>
                    {t("book_demo.email")}*
                  </StyledFormLabel>
                  <FormInput
                    name="email"
                    label={t("book_demo.email")}
                    placeholder="Email"
                  />
                  <StyledErrorMessage name="email" />
                  <StyledFormLabelWithHelperText>
                    {t("book_demo.email_helperText")}
                  </StyledFormLabelWithHelperText>
                </FormControl>

                <FormControl>
                  <StyledFormLabel fontSize={18}>
                    {t("book_demo.name")}*
                  </StyledFormLabel>
                  <FormInput
                    name="name"
                    label={t("book_demo.name")}
                    placeholder={t("book_demo.name")}
                  />
                </FormControl>
                <StyledFormLabelWithHelperText>
                  {t("book_demo.name_helperText")}
                </StyledFormLabelWithHelperText>
                <FormControl>
                  <StyledFormLabel fontSize={18}>
                    {t("book_demo.phone")}*
                  </StyledFormLabel>
                  <FormInput
                    name="phone"
                    label={t("book_demo.phone")}
                    placeholder={t("book_demo.phone")}
                  />
                  <StyledFormLabelWithHelperText>
                    {t("book_demo.phone_helperText")}
                  </StyledFormLabelWithHelperText>
                </FormControl>

                <StyledButton
                  size="md"
                  type="submit"
                  color={palette.white}
                  fs={22}
                  sx={{
                    mt: 2,
                  }}
                >
                  {t("form.button_submit")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </Box>
      </InformationModal>
    </div>
  );
};

export default BookDemoModal;
