// src/components/forms/FormSelect.tsx
import React from "react";
import { Field, FieldProps } from "formik";
import {
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Grid,
  Option,
  Box,
  Chip,
} from "@mui/joy";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../forms/form_labels";
import { StyledErrorMessage } from "../forms/messages";
import { DefaultInputStyle } from "./input_types";
import { ISelectOption } from "./form_select";

interface FormMultiSelectProps {
  name: string;
  label: string;
  helperText?: string;
  options: ISelectOption[];
  placeholder?: string;
  style?: React.CSSProperties;
  required?: boolean;
}

export const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
  name,
  label,
  helperText,
  options,
  placeholder,
  required = false,
  style,
}) => {
  const palette = usePalette();
  return (
    <FormControl>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              multiple
              required={required}
              placeholder={placeholder}
              {...field}
              onChange={(_, newValue) => {
                form.setFieldValue(field.name, newValue);
              }}
              renderValue={(selected: any) => (
                <Box sx={{ display: "flex", gap: "0.25rem" }}>
                  {selected.map((selectedOption: ISelectOption) => (
                    <Chip variant="soft" color="primary">
                      {selectedOption.label}
                    </Chip>
                  ))}
                </Box>
              )}
              style={{
                ...DefaultInputStyle,
                ...style,
              }}
              sx={{
                [`& [aria-expanded="false"]`]: {
                  border: "none !important",
                  backgroundColor: "transparent !important",
                },
              }}
            >
              {options.map((option) => (
                <Option key={option.id} value={option.value}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    flexDirection={"row"}
                  >
                    <StyledText level="body-sm" color={palette.alwaysBlack}>
                      {option.label}
                    </StyledText>
                    {option.description && (
                      <Tooltip title={option.description}>
                        <HelpOutlineIcon
                          style={{
                            marginLeft: "5px",
                            color: palette.charcoal,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Option>
              ))}
            </Select>
          );
        }}
      </Field>
      {helperText && (
        <StyledFormLabelWithHelperText>
          {helperText}
        </StyledFormLabelWithHelperText>
      )}
    </FormControl>
  );
};

export default FormMultiSelect;
