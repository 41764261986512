// ThemeContext.tsx
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface IPalette {
  [key: string]: string;
}

interface ThemeContextProps {
  theme: "light" | "dark";
  toggleTheme: () => void;
  PALLETTE: IPalette;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};

interface ThemeProviderProps {
  children: ReactNode;
}

// Your color variables
const imperialRed = "#FF3A49"; // Imperial Red
const spaceCadet = "#22223B"; // Space Cadet
const ultraViolet = "#4A4E69"; // Ultra Violet
const isabelline = "#F2E9E4"; // Isabelline
const mint = "#79C99E"; // Mint

// Define your light and dark palettes
export const lightPaletteExported: IPalette = {
  // Primary Colors
  primaryMain: imperialRed, // Main action color
  primaryDark: spaceCadet, // Darker shade for hover effects
  primaryLight: mint, // Lighter shade for accents

  // Additional Shades for Primary
  primaryExtraDark: "#B82434", // Extra dark imperial red
  primaryExtraLight: "#FF6F71", // Extra light imperial red

  // Secondary Colors
  secondaryMain: mint, // Secondary action color
  secondaryDark: ultraViolet, // Darker secondary color
  secondaryLight: isabelline, // Light secondary color

  // Additional Shades for Mint (Secondary)
  secondaryExtraDark: "#209C8E", // Darker mint
  secondaryExtraLight: "#A0E8E0", // Lighter mint

  // Success Colors
  successMain: mint, // Success messages
  successDark: "#006822", // Dark green
  successLight: "#73BA9B", // Light mint

  // Additional Shades for Space Cadet
  spaceCadet: "#2B2D42", // Dark blue-gray (main)
  spaceCadetExtraDark: "#1D1F31", // Darker space cadet
  spaceCadetExtraLight: "#444760", // Lighter space cadet

  // Additional Shades for Ultra Violet
  ultraVioletExtraDark: "#472A78", // Darker ultra violet
  ultraVioletExtraLight: "#7B57C2", // Lighter ultra violet

  // Additional Shades for Isabelline
  isabellineExtraDark: "#D3CEC1", // Darker isabelline
  isabellineExtraLight: "#FFFFFF", // Lighter isabelline

  // Warning and Error Colors
  warningMain: "#FDE74C", // Yellow for warnings
  errorMain: imperialRed, // Error messages

  // Neutral Colors
  black: "#000000",
  white: "#FFFFFF",
  greyLight: "#B1B2B1", // Light Grey
  greyDark: "#919190", // Dark Grey

  // Background and Text
  backgroundDefault: isabelline, // Off-white background
  backgroundPaper: "#FFFFFF", // Paper white
  textPrimary: spaceCadet, // Main text color
  textSecondary: ultraViolet, // Secondary text color

  // Additional Colors
  accentMain: ultraViolet, // Accent elements
  linkMain: imperialRed, // Links
  charcoal: spaceCadet, // Dark elements
  charcoalTransparent: "#36415680", // Semi-transparent overlay
  offWhite: isabelline,
  offBlack: "#1E1E1E",

  alwaysWhite: "#FFFFFF",
  alwaysBlack: "#000000",
};

export const darkPaletteExported: IPalette = {
  // Primary Colors
  primaryMain: imperialRed,
  primaryDark: isabelline,
  primaryLight: mint,

  // Additional Shades for Primary
  primaryExtraDark: "#B82434", // Extra dark imperial red
  primaryExtraLight: "#FF6F71", // Extra light imperial red

  // Secondary Colors
  secondaryMain: mint,
  secondaryDark: ultraViolet,
  secondaryLight: spaceCadet,

  // Additional Shades for Mint (Secondary)
  secondaryExtraDark: "#209C8E",
  secondaryExtraLight: "#A0E8E0",

  // Success Colors
  successMain: mint,
  successDark: "#10B981",
  successLight: "#6EE7B7",

  // Additional Shades for Space Cadet
  spaceCadet: "#2B2D42",
  spaceCadetExtraDark: "#1D1F31",
  spaceCadetExtraLight: "#444760",

  // Additional Shades for Ultra Violet
  ultraVioletExtraDark: "#472A78",
  ultraVioletExtraLight: "#7B57C2",

  // Additional Shades for Isabelline
  isabellineExtraDark: "#D3CEC1",
  isabellineExtraLight: "#FFFFFF",

  // Warning and Error Colors
  warningMain: "#FBBF24",
  errorMain: imperialRed,

  // Neutral Colors
  black: "#FFFFFF",
  white: "#2b2b2b",
  greyLight: "#6B7280",
  greyDark: "#374151",

  // Background and Text
  backgroundDefault: spaceCadet,
  backgroundPaper: ultraViolet,
  textPrimary: isabelline,
  textSecondary: mint,

  // Additional Colors
  accentMain: ultraViolet,
  linkMain: imperialRed,
  charcoal: isabelline,
  charcoalTransparent: "#747d8c",
  offWhite: "#17171E",
  offBlack: isabelline,

  alwaysWhite: "#FFFFFF",
  alwaysBlack: "#000000",
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // Initialize theme from localStorage, system preferences, or default to 'light'
  const [theme, setTheme] = useState<"light" | "dark">(() => {
    const savedTheme = localStorage.getItem("appTheme");
    if (savedTheme === "dark" || savedTheme === "light") {
      return savedTheme;
    }
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    return prefersDarkMode ? "dark" : "light";
  });

  // Update localStorage whenever the theme changes
  useEffect(() => {
    localStorage.setItem("appTheme", theme);
  }, [theme]);

  // Update background color whenever the theme changes
  useEffect(() => {
    if (theme === "light") {
      document.documentElement.style.setProperty(
        "--background-color",
        lightPaletteExported.offWhite
      );
    } else {
      document.documentElement.style.setProperty(
        "--background-color",
        darkPaletteExported.offWhite
      );
    }
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const lightPalette: IPalette = lightPaletteExported;
  const darkPalette: IPalette = darkPaletteExported;

  // Select the palette based on the current theme
  const PALLETTE = theme === "light" ? lightPalette : darkPalette;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, PALLETTE }}>
      {children}
    </ThemeContext.Provider>
  );
};
