import React, { useState, useRef, useEffect } from "react";
import { generateRoute, ROUTES } from "../../routes/def";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { userHasRole } from "../../utils/roles/has_role";
import { RoleType } from "../../types";
import { isEventManager } from "../../utils/roles/manager";
import StyledText from "../text/styled_text";
import { Box, Link, Stack } from "@mui/joy";
import MenuList from "@mui/joy/MenuList";
import MenuItem from "@mui/joy/MenuItem";
import { Popper } from "@mui/base/Popper";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { styled } from "@mui/joy/styles";
import styles from "./nav.module.css";
import { adjustTransparency, lightenColor } from "../../utils/manager/color";
import usePalette from "../../theme/use_palette";
import { useNavigate } from "react-router-dom";
import MenuItemComponent from "./menu_item";

const Popup = styled(Popper)({
  zIndex: 1000,
});

const CustomStyledText = styled(StyledText)(({ theme }) => ({
  margin: "0 16px",
  letterSpacing: "0.075em",
  transition: "all 0.3s  ease-in-out",
}));

const CustomLink = styled(Link)(({ theme }) => ({
  color: usePalette().black,
  textDecoration: "none",
  fontWeight: 600,
  transition: "all 0.3s  ease-in-out",
  "&:hover": {
    textDecoration: "underline",
    textShadow: "0 -5px 5px rgba(0, 0, 0, 0.3)",
  },
}));

interface NavLinkTextProps {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  isDropdown?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const NavLinkText: React.FC<NavLinkTextProps> = ({
  href,
  onClick,
  children,
  isDropdown = false,
  onMouseEnter,
  onMouseLeave,
}) => (
  <CustomStyledText
    level="body-sm"
    color={"inherit"}
    fontSize={20}
    fontWeight={600}
    style={{
      margin: "0 16px",
      letterSpacing: "0.025em",
    }}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <CustomLink href={href} className={styles.link}>
      {children}
    </CustomLink>
  </CustomStyledText>
);

interface NavLinksProps {
  textColor: string;
  onClick?: () => void; // For mobile navigation to close the drawer
}

const NavLinks: React.FC<NavLinksProps> = ({ textColor, onClick }) => {
  const { t } = useTranslation();
  const { user: currentUser } = useSelector((state: RootState) => state.user);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductLinkHovered, setIsProductLinkHovered] = useState(false);
  const [isPopupHovered, setIsPopupHovered] = useState(false);
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLDivElement>(null);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const palette = usePalette();

  const toggleMenu = () => {
    navigate(ROUTES.PRODUCT);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Tab") {
      setIsMenuOpen(false);
    } else if (event.key === "Escape") {
      buttonRef.current!.focus();
      setIsMenuOpen(false);
    }
  };

  const handleProductLinkMouseEnter = () => {
    setIsProductLinkHovered(true);
    setIsMenuOpen(true);
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  };

  const handleProductLinkMouseLeave = () => {
    setIsProductLinkHovered(false);
    closeTimeoutRef.current = setTimeout(() => {
      if (!isPopupHovered) {
        handleClose();
      }
    }, 250);
  };

  const handlePopupMouseEnter = () => {
    setIsPopupHovered(true);
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  };

  const handlePopupMouseLeave = () => {
    setIsPopupHovered(false);
    handleClose();
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {isLoggedIn &&
        currentUser &&
        userHasRole(currentUser, RoleType.SUPER_ADMIN) && (
          <Box>
            <NavLinkText href={ROUTES.EVENTS} onClick={onClick}>
              {t("navigation.events")}
            </NavLinkText>
          </Box>
        )}

      {/* Dropdown for Product Features */}
      <Box ref={buttonRef} sx={{ display: "flex", alignItems: "center" }}>
        <NavLinkText
          isDropdown
          onClick={toggleMenu}
          onMouseEnter={handleProductLinkMouseEnter}
          onMouseLeave={handleProductLinkMouseLeave}
        >
          {t("navigation.product")}
        </NavLinkText>
        <Popup
          role={undefined}
          open={isMenuOpen}
          anchorEl={buttonRef.current}
          disablePortal
          onMouseEnter={handlePopupMouseEnter}
          onMouseLeave={handlePopupMouseLeave}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          <ClickAwayListener
            onClickAway={(event) => {
              if (event.target !== buttonRef.current) {
                handleClose();
              }
            }}
          >
            <MenuList
              variant="outlined"
              onKeyDown={handleListKeyDown}
              sx={{ boxShadow: "md", backgroundColor: usePalette().white }}
            >
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.ticket_distribution"
                route={generateRoute(ROUTES.PRODUCT_TICKET_DISTRIBUTION, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.event_management"
                route={generateRoute(ROUTES.PRODUCT_EVENT_MANAGEMENT, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.event_pages"
                route={generateRoute(ROUTES.PRODUCT_EVENT_PAGES, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.team_management"
                route={generateRoute(ROUTES.PRODUCT_TEAM_MANAGEMENT, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.beta_program"
                route={generateRoute(ROUTES.PRODUCT_BETA_PROGRAM, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.manager_onboarding"
                route={generateRoute(ROUTES.PRODUCT_MANAGER_ONBOARDING, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.event_forms"
                route={generateRoute(ROUTES.PRODUCT_EVENT_FORMS, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.data_security"
                route={generateRoute(ROUTES.PRODUCT_DATA_SECURITY, {})}
                handleClose={handleClose}
              />
              <MenuItemComponent
                textColor={palette.charcoal}
                text="product.titles.payments"
                route={generateRoute(ROUTES.PRODUCT_PAYMENTS, {})}
                handleClose={handleClose}
              />
            </MenuList>
          </ClickAwayListener>
        </Popup>
      </Box>

      <Box>
        <NavLinkText href={generateRoute(ROUTES.PRICING, {})} onClick={onClick}>
          {t("navigation.pricing")}
        </NavLinkText>
      </Box>
      <Box>
        <NavLinkText
          href={
            currentUser
              ? generateRoute(
                  isEventManager(currentUser)
                    ? ROUTES.MANAGER_DASHBOARD
                    : ROUTES.BECOME_A_MANAGER,
                  {}
                )
              : ROUTES.MANAGER_DASHBOARD
          }
          onClick={onClick}
        >
          {t("navigation.manager")}
        </NavLinkText>
      </Box>
    </Stack>
  );
};

export default NavLinks;
