import React, { useEffect, useState } from "react";
import { Select, Option } from "@mui/joy";
import usePalette from "../../theme/use_palette"; // Correct import path for usePalette
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { setLanguage } from "../../redux/features/languageSlice";

// Use "en" and "se" since those are the language codes in i18n
const lngs = [
  {
    code: "en",
    name: "English",
    nativeName: "English",
    flagCode: "gb", // Still using the 'gb' flag for English
  },
  {
    code: "se",
    name: "Swedish",
    nativeName: "Svenska",
    flagCode: "se", // Swedish flag for 'se'
  },
];

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { language: storedLanguage } = useSelector(
    (state: RootState) => state.language
  );

  const palette = usePalette();

  // Map i18n language code to flag code
  const getFlagCode = (language: string) => {
    const lng = lngs.find((l) => l.code === language);
    return lng ? lng.flagCode : "en"; // default to 'gb' if not found
  };

  // Initialize selectedLanguage to flag code corresponding to i18n.language
  const [selectedLanguage, setSelectedLanguage] = useState(
    getFlagCode(i18n.language)
  );

  const handleChange = (event: any, newValue: string | null) => {
    if (!newValue) return;
    // Change the i18n language based on the selected option
    i18n.changeLanguage(newValue);
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      // Update selectedLanguage based on the new i18n language
      const flagCode = getFlagCode(i18n.language);
      setSelectedLanguage(flagCode);
      // Store the language in Redux
      dispatch(setLanguage(i18n.language));
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n, dispatch]);

  useEffect(() => {
    // Update the selectedLanguage when storedLanguage changes
    const flagCode = getFlagCode(storedLanguage);
    setSelectedLanguage(flagCode);
    i18n.changeLanguage(storedLanguage);
  }, [storedLanguage, i18n]);

  return (
    <Select
      value={i18n.language}
      onChange={handleChange}
      renderValue={() => (
        <img
          alt={selectedLanguage}
          src={`https://flagcdn.com/192x144/${selectedLanguage}.png`}
          style={{
            width: 20,
            height: "auto",
          }}
        />
      )}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        color: palette.charcoal,
        borderColor: palette.charcoal,
        maxWidth: 120,
        "&:hover": {
          borderColor: palette.charcoal,
        },
      }}
    >
      {lngs.map((lng) => (
        <Option value={lng.code} key={lng.code}>
          <img
            alt={lng.nativeName}
            src={`https://flagcdn.com/192x144/${lng.flagCode}.png`}
            style={{
              marginRight: 8,
              width: 30,
              height: "auto",
            }}
          />
          {lng.nativeName}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
