import { Typography } from "@mui/joy";
import usePalette from "../../theme/use_palette";
import React from "react";

interface TitleProps {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Title = React.forwardRef<HTMLDivElement, TitleProps>(
  (
    {
      color,
      fontSize = 38,
      fontWeight = 700,
      children,
      style,
      ...props
    },
    ref
  ) => {
    const palette = usePalette();
    return (
      <Typography
        level="h1"
        fontFamily={"Josefin sans"}
        fontSize={fontSize}
        fontWeight={fontWeight}
        style={{
          ...style,
          color: color || palette.primaryDark,
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        ref={ref}
        {...props}
      >
        {children}
      </Typography>
    );
  }
);

export default Title;