import { Box, Link, Stack } from "@mui/joy";
import { IEvent, ITicketRelease } from "../../../types";
import StyledText from "../../../components/text/styled_text";
import usePalette from "../../../theme/use_palette";
import TicketRelease from "../../../components/events/ticket_release";
import { Trans, useTranslation } from "react-i18next";
import StyledButton from "../../../components/buttons/styled_button";

interface ShowEventsTicketReleaseProps {
  ticketReleases: ITicketRelease[];
  event: IEvent;
}

const ShowEventsTicketReleases: React.FC<ShowEventsTicketReleaseProps> = ({
  ticketReleases,
  event,
}) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Box>
      {ticketReleases.length === 0 && (
        <StyledText
          color={palette.charcoalTransparent}
          level="body-sm"
          fontSize={22}
          fontWeight={500}
          style={{
            marginTop: "1rem",
          }}
        >
          {t("event.no_ticket_releases")}
        </StyledText>
      )}
      <Stack
        spacing={2}
        sx={{
          p: 0,
        }}
      >
        {ticketReleases.map((ticketRelease, i) => {
          const key = `${event.name}-${i}`;

          return <TicketRelease ticketRelease={ticketRelease} key={key} />;
        })}
      </Stack>
    </Box>
  );
};

export default ShowEventsTicketReleases;
