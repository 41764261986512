import { title } from "process";
import seFaq from "../assets/faq/se_faq.json";

const seTranslations = {
  navigation: {
    events: "Evenemang",
    create_event: "Skapa evenemang",
    teams: "Grupper",
    contact: "Kontakt",
    login: "Logga in",
    product: "Produkt",
    pricing: "Priser",
    manager: "Arrangör",
  },

  become_a_manager: {
    welcome: "Välkommen till tessera!",
    proceed: "Vem är du?",
    customer: "Kund",
    manager: "Arrangör",
    skip: "Hoppa över",
    select_plan: "Välj paket",
    continue_as_manager: "Fortsätt som arrangör",
    choose_plan:
      "Välj det paket som passar dig bäst. När du kontaktar oss, vänligen låt oss veta vilken paket du är intresserad av och vad du skulle vilja veta mer om.",
    full_list_of_features: "Fullständig lista över funktioner",
    selected_plan: "Vald paket",
  },

  tooltips: {
    add_ticket_type: "Lägg till biljettgrupp",
    manage_ticket_releases: "Hantera biljettsläpp för detta evenemang.",
    manage_tickets:
      "Tabellen visar alla biljettförfrågningar och biljetter för detta evenemang.",
    manage_tickets_custom_event_form_description:
      "Här kan du se alla anpassade evenemangsfält som användare har fyllt i när de begärde biljetter.",
    must_be_edited: "Formuläret måste vara giltigt innan du kan fortsätta.",
  },

  form: {
    required_description: "Fält markerade med * är obligatoriska.",
    event_details: {
      name: "Namn",
      name_helperText: "Vad är namnet på ditt evenemang?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vad ditt evenemang handlar om. Vad kan deltagarna förvänta sig? Markdown stöds.",
      date: "Datum",
      date_helperText: "När är ditt evenemang?",
      end_date: "Slutdatum",
      end_date_helperText: "När slutar ditt evenemang?",
      location: "Plats",
      location_helperText: "Var hålls ditt evenemang?",
      team: "Grupp",
      team_helperText:
        "Vilken grupp står värd för ditt evenemang? Du måste koppla evenemanget till ett grupp. Om du inte tillhör en grupp kan du skapa ett, läs mer på startsidan.",
      private_event: "Privat evenemang",
      private_event_helperText:
        "Är ditt evenemang privat? Om så är fallet kan bara personer med länken se det.",
      common_locations: "Vanligt Använda Platser",
      collect_food_preferences: "Samla in matpreferenser",
      collect_food_preferences_helperText:
        "Vill du samla in matpreferenser från deltagarna? Om så är fallet kommer deltagarna att kunna ange sina matpreferenser när de begär en biljett.",
    },
    ticket_release: {
      name: "Namn",
      name_helperText: "Vad är namnet på biljettsläppet?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vilken typ av biljetter du släpper. Vad kan deltagarna förvänta sig? Markdown stöds.",
      available_at: "Tillgänglig från",
      available_at_helperText:
        "När släpps biljetterna? Detta är tidpunkten då användare kan börja önska biljetter.",
      closes_at: "Stänger",
      closes_at_helperText:
        "När stänger biljettsläppet? Detta är tidpunkten då användare inte längre kan önska biljetter.",
      ticket_release_method: "Metod för biljettsläpp",
      ticket_release_method_helperText: "Hur vill du distribuera biljetter?",
      lottery_duration: "Lottningstid (minuter)",
      lottery_duration_helperText:
        "För Först till kvarn, definierar lottningstiden inom hur många minuter efterfrågade biljetter kommer att ingå i en lottning. Om fler biljetter efterfrågas än tillgängligt, kommer alla deltagare som önskar biljetter inom denna tidsram att delta i en lottning, övriga blir reserv.",
      max_tickets_per_user: "Max biljetter per användare",
      max_tickets_per_user_helperText:
        "Hur många biljetter kan en användare önska? Alltid 1 för nuvarande.",
      tickets_available: "Antal biljetter",
      tickets_available_helperText:
        "Hur många biljetter kommer att vara tillgängliga?",
      notification_method: "Notifieringsmetod",
      notification_method_helperText:
        "Hur vill du meddela användare om biljettsläppet?",
      cancellation_policy: "Avbokningspolicy",
      cancellation_policy_helperText: "Vad är avbokningspolicyn?",
      reserved_ticket_release: "Reserverat biljettsläpp",
      reserved_ticket_release_helperText:
        "Ett reserverat biljettsläpp innehåller biljetter som är reserverade för specifika användare. En promokod krävs för att få tillgång till denna biljett. Kom ihåg att du kan lägga till fler biljettsläpp senare.",
      promo_code: "Promokod",
      promo_code_helperText:
        "Vilken promokod ska användas för att få tillgång till detta reserverade biljettsläpp?",

      selective_description: "Beskriv allokeringsmetoden",
      selective_description_helperText:
        "Ange hur ditt team planerar att allokera biljetter. Denna information kommer att visas för användaren när de begär en biljett.",
      save_template: "Spara som mall",
      save_template_helperText:
        "Spara detta biljettsläpp som en mall för att använda det för framtida evenemang.",

      payment_deadline: "Betalningsdeadline",
      payment_deadline_helperText:
        "När är betalningsdeadlinen för användare som initialt fick en biljett?",
      reserve_payment_duration: "Reserverad Betalningstid",
      reserve_payment_duration_helperText:
        "När en reserv får en biljett, hur lång tid har de på sig att betala innan den ges till nästa person i kön?",
      allocation_cut_off: "Stopp för Allokering",
      allocation_cut_off_helperText:
        "När kommer allokeringen av biljetter att avslutas? Detta är när systemet kommer att sluta allokera biljetter.",
    },
    ticket_types: {
      name: "Namn",
      name_helperText: "Vad är namnet på denna biljett?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vad som ingår i denna biljett. Markdown stöds.",
      price: "Pris (SEK)",
      price_helperText: "Hur mycket kostar denna biljett?",
      save_template: "Spara som mall",
      save_template_helperText:
        "Spara denna biljett som en mall för att använda den för framtida evenemang.",
    },

    contact: {
      title: "Kontakt",
      email: "Din E-post",
      plan: "Paket",
      email_helperText: "Vad är kontakt-e-posten för denna biljett?",
      subject: "Ämne",
      subject_helperText: "Vad är ämnet för denna biljett?",
      name: "Ditt Namn",

      name_helperText: "Vad är namnet på kontaktpersonen?",
      message: "Meddelande",
      message_helperText: "Vad är meddelandet för denna biljett?",
      success: "Ditt meddelande har skickats!",
      fail: "Något gick fel. Försök igen senare.",
      description:
        "Om du har några frågor, problem eller förslag, tveka inte att kontakta oss. Vi kommer att återkomma till dig så snart som möjligt.",
      team_name: "Grupp",
      team_helperText: "Vilken grupp kontaktar du?",
    },
    addon: {
      name: "Namn",
      name_helperText: "Vad är namnet på detta tillägg?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vad detta tillägg handlar om. Markdown stöds.",
      price: "Pris (SEK)",
      price_helperText: "Hur mycket kostar detta tillägg?",
      max_quantity: "Max kvantitet",
      max_quantity_helperText:
        "Hur många av detta tillägg kan en användare köpa?",
      is_enabled: "Aktiverad",
      is_enabled_helperText:
        "Om tilläget är aktiverat kommer det att visas för användaren.",
      contains_alcohol: "Innehåller alkohol",
      contains_alcohol_helperText: "Innehåller detta tillägg alkohol?",
    },
    event_fields: {
      title: "Eventformulär",
      subtitle:
        "Lägg till och redigera specialgjorda fält för ditt evenemang. Dessa fält kommer att visas för användare när de begär/köper en biljett.",
      label_name: "Namn",
      label_description: "Beskrivning",
      label_type: "Typ",
      label_required: "Obligatorisk",
      form_field_description: "Beskrivning",
      form_field_description_helperText:
        "Beskriv vilken ytterligare information du vill samla in från användare och varför. Markdown stöds.",
      delete_field_confirm:
        "Är du säker på att du vill ta bort detta fält? Att ta bort detta fält kan resultera i förlust av svarsinformation. Du måste spara efter att ha tagit bort!",
      select_field_type: "Välj en fälttyp",
      field_name: "Fältnamn",
      field_name_placeholder: "Ange fältnamn",
      field_description: "Beskrivning",
      field_description_placeholder: "Ange fältbeskrivning",
      required: "Obligatorisk",
      options_label: "Alternativ",
      option: "Alternativ",
      option_placeholder: "Ange alternativ",
      button_add_option: "Lägg till alternativ",
      button_delete_option: "Ta bort alternativ",
      delete_field: "Ta bort fält",
    },

    manager: {
      setup: {
        business_details: {
          legal_name: "Företagsnamn",
          legal_name_helperText: "Ditt företags juridiska namn.",
          corporate_id: "Organisationsnummer",
          corporate_id_helperText: "Ditt företags organisationsnummer.",
          business_email: "Företagsemail",
          business_email_helperText: "Ditt företags emailadress.",
          country: "Land",
          country_helperText: "Ditt företags land.",
          phone_number: "Telefonnummer",
          phone_number_helperText: "Ditt företags telefonnummer.",
          address_line1: "Adressrad 1",
          address_line1_helperText: "Ditt företags adressrad 1.",
          address_line2: "Adressrad 2",
          address_line2_helperText: "Ditt företags adressrad 2.",
          city: "Stad",
          city_helperText: "Ditt företags stad.",
          postal_code: "Postnummer",
          postal_code_helperText: "Ditt företags postnummer.",
          terms_and_conditions_url: "Villkor URL",
          terms_and_conditions_url_helperText:
            "URL till ditt företags villkor.",
          privacy_policy_url: "Integritetspolicy URL",
          privacy_policy_url_helperText:
            "URL till ditt företags integritetspolicy.",
        },
      },
    },

    banking_details: {
      vat: "Moms (%)",
      vat_helperText: "Ange din momsprocent för detta evenemang.",
    },

    domain_details: {
      slug: "Subdomän",
      slug_helperText: "Ange subdomänen för ditt evenemang.",
    },

    organization: {
      store_customization: {
        title: "Anpassa butikens utseende",
        description:
          "Anpassa utseendet och känslan av din grupps online butik. Detta kommer att vara synligt när användare betalar för biljetter till event organiserade av ditt team.",
        background_color: "Bakgrundsfärg",
        background_color_helperText: "Välj en bakgrundsfärg för din butik.",
        brand_color: "Varumärkesfärg",
        brand_color_helperText: "Välj en varumärkesfärg för din butik.",
        accent_color: "Accentfärg",
        accent_color_helperText: "Välj en accentfärg för din butik.",
        rect_shape: "Rektangelns form",
        rect_shape_helperText: "Välj formen på dina knappar.",
        rect_shapes: {
          rounded: "Rundad",
          edgy: "Kantig",
          pill: "Piller",
        },
        font_type: "Typsnitt",
        font_type_helperText: "Välj typsnitt för din butik.",
        font_types: {
          sans_serif: "Sans Serif",
          serif: "Serif",
          mono: "Monospace",
        },
        logo_url: "Logotyp URL",
        logo_url_helperText: "Ange URL för din organisations logotyp.",
        icon_url: "Ikon URL",
        icon_url_helperText: "Ange URL för din organisations ikon.",
      },
    },

    button_sign_in: "Logga in",
    button_add_field: "Lägg till fält",
    button_clear: "Rensa",
    button_next: "Nästa",
    button_create: "Skapa",
    button_edit: "Redigera",
    button_back: "Tillbaka",
    button_invite: "Bjud in",
    button_restart: "Starta om",
    button_confirm: "Bekräfta",
    button_cancel: "Avbryt",
    button_create_event: "Skapa evenemang",
    button_save: "Spara",
    button_submit: "Submit",
    button_manage: "Hantera",
    button_details: "Detaljer",
    button_request: "Önska biljett(er)",
    button_purchase: "Betala",
    button_delete: "Radera",
    button_send: "Skicka",
    button_check_in: "Checka in",
    button_update_gdpr: "Spara och uppdatera GDPR samtycke",
    button_send_out: "Utskick",
    button_economy: "Ekonomi",
    generate_sales_report: "Generera försäljningsrapport",
  },
  create_event: {
    title: "Skapa evenemang",
    create_event_description:
      "Skapa ett evenemang för att hantera biljettsläpp, deltagare och mer. Ett evenemang består av flera delar, men tessera strävar efter att göra det så enkelt som möjligt. Vi kommer att guida dig genom processen steg för steg.",
    event_details_title: "Evenemangsdetaljer",
    event_details_description:
      "Låt oss börja med grunderna. Vad är detaljerna för ditt evenemang?",
    ticket_release_title: "Skapa biljettsläpp",
    ticket_release_description:
      "Vi fortsättar med biljettsläpp. Här kan du ange när en omgång biljetter blir tillgängliga för folk att önska. Du kan också skapa fler biljettsläpp senare, på sidan för att redigera evenemang.",
    ticket_release_description_example:
      "Till exempel kan du skapa en biljettsläpp för tidiga fågelbiljetter, med vanliga och VIP-biljetter som kräver en kampanjkod. Sedan kan du skapa en annan biljettsläpp för vanliga biljetter, med sina egna biljettbatcher.",
    ticket_release: "Biljettsläpp",
    ticket_release_helperText:
      "Låt oss definiera detaljerna för detta biljettsläpp.",
    confirm_event_creation_restart_text:
      "Är du säker på att du vill starta om skapandet av evenemang? Allt framsteg kommer att gå förlorat.",
    ticket_types_title: "Biljettgrupper",
    ticket_types_description:
      "Nästa steg... Biljettgrupper. Här kommer du att definiera de olika typerna av biljetter som kommer att vara tillgängliga för ditt evenemang under det tidigare biljettsläppet. Varje biljettsläpp kan ha flera biljettgrupper. Du kan också skapa fler biljettyper senare, på sidan för att redigera evenemang. Kom ihåg att det totala antalet biljetter som definierats i föregående steg kommer att fördelas mellan biljettgrupperna.",
    ticket_types: "Biljettgrupper",
    ticket_types_helperText:
      "Låt oss definiera de olika grupperna av biljetter som kommer att vara tillgängliga för det tidigare biljettsläppet. Clicka på en biljettgrupp för att redigera den.",
    finish_title: "Det var det!",
    finish_description:
      "Du har nu fyllt i alla detaljer för ditt evenemang. Klicka på knappen nedan för att skapa ditt evenemang. Du kan också gå tillbaka och redigera ditt evenemang genom att klicka på tillbaka-knappen. Men du kan också redigera ditt evenemang senare på redigera evenemang-sidan.",
    no_teams:
      "Du är för närvarande inte med i en grupp och kan därför inte skapa ett evenemang. Kontakta din organisations administratör för att bli tillagd i en grupp.",
    review_submit_title: "Då var det klart!",
  },

  // Profile
  profile: {
    title: "Profil",
    user_info_title: "Profil",
    full_name: "För- och efternamn",
    username: "Användarnamn",
    roles: "Roller",
    teams: "Grupper",
    email: "E-post",

    links_and_buttons: {
      your_ticket_requests: "Mina biljettförfrågningar",
      your_tickets: "Mina biljetter",
      your_teams: "Mina grupper",
    },

    tabs: {
      info: "Profil",
      tickets: "Dina Biljetter",
    },

    food_preferences: {
      title: "Matpreferenser",
      saved: "Dina matpreferenser har sparats.",
      allergies_and_dietary_restrictions_select:
        "Allergier och kost (Välj alla som gäller)",
      allergies_and_dietary_restrictions: "Allergier och kost",
      allergies_and_dietary_restrictions_helperText:
        "Välj alla som gäller, lämna blankt om inga gäller.",
      additional_notes: "Ytterligare anteckningar",
      additional_notes_helperText:
        "Ange eventuella ytterligare anteckningar här angående dina matpreferenser. Lämna blankt om du inte har några.",
      privacy_policy_title: "Integritetspolicy",
      gdpr_agree_helperText:
        "Jag har läst och godkänner behandlingen av mina personuppgifter för att hantera mina matpreferenser enligt <1>Integritetspolicy för matpreferenser</1>",
      no_preferences: "Du har inga matpreferenser.",
      select_placeholder: "Välj...",
    },

    your_ticket_requests: {
      title: "Dina biljettförfrågningar",
      description:
        "Nedan visas alla evenemang du har en biljett eller biljettförfrågan för. Klicka på en för att se mer detaljer.",
      upcoming_events: "Kommande evenemang",
      no_upcoming_events: "Du har inga kommande evenemang.",
      past_events: "Tidigare evenemang",
      no_past_events: "Du har inga tidigare evenemang.",
      no_ticket_requests: "Du har inga biljettförfrågningar.",
      show_past_events: "Visa",
      hide_past_events: "Dölj",
    },

    your_tickets: {
      title: "Dina biljetter",
      description:
        "Här kan du se alla biljetter du har fått. Du kan ge upp din biljett genom att klicka på biljetten och sedan välja alternativet 'Jag vill inte längre delta', vilket kommer att ge din biljett till nästa person i kön. Om du ännu inte har tilldelats en biljett eller reservbiljett kan du se dina biljettförfrågningar <1>här</1>.",
      upcoming_events: "Kommande evenemang",
      no_upcoming_events: "Du har inga kommande evenemang.",
      past_events: "Tidigare evenemang",
      no_past_events: "Du har inga tidigare evenemang.",
      no_tickets: "Du har inga biljetter.",
      order_id: "Order-ID",
      show_deleted: "Visa raderade",
    },

    your_teams: {
      title: "Dina grupper",
      description:
        "Här kan du se alla grupper du är en del av. Klicka på en grupp för att se mer detaljer. Du kan skapa en ny grupp <1>här</1>.",
      not_part_of_any_teams: "Inga grupper hittades.",
      add_user: "Lägg till användare",
      add_user_helperText:
        "Ange e-posten för användaren du vill lägga till i denna grupp. Du kan ändra deras roll senare.",
      manage_team_events: "Hantera grupphändelser",
      no_events: "Det finns inga event i denna grupp. Skapa en nu <1>här</1>.",
      users: "Användare",
      no_users: "Det finns inga användare i denna grupp.",
      delete_team: "Radera grupp",
      team_name_title: "Gruppnamn",
      edit_team: "Redigera grupp",
      delete_team_confirmation_title: "Bekräfta radering av grupp",
      delete_team_confirmation:
        "Är du säker på att du vill radera denna grupp? Denna åtgärd kan inte ångras.",
    },
  },

  templates: {
    title: "Sparade Mallar",
    ticket_releases: {
      description:
        "Du kan skapa nya mallar genom att markera kryssrutan 'Spara som mall' när du skapar en biljettsläpp. Här kan du se alla dina sparade mallar. Klicka på 'Skapa' för att skapa en ny händelse från en mall.",
      no_templates: "Det finns inga tillgängliga mallar.",
    },
    ticket_types: {
      description:
        "Mallar för biljettbatcher är lite annorlunda jämfört med biljettsläpp. Att spara en mall innebär att du kommer att behålla den mallen för det biljettsläpp du redigerar. Att redigera din mall kommer automatiskt att uppdatera alla biljettsläpp som använder den mallen.",
      no_templates: "Det finns inga tillgängliga mallar.",
    },
  },

  create_team: {
    title: "Skapa grupp",
    what_is_a_team: "Vad är en grupp?",

    description:
      "Här kan du skapa en grupp. Grupper används för att organisera evenemang och hantera användare. Det gör att gruppens ledare kan skapa evenemang, hantera biljettförsäljning och planera evenemanget mer effektivt. Du kan bjuda in andra användare att gå med i din grupp och ge dem olika behörigheter. Du är inte heller begränsad till en grupp, du kan skapa så många du vill, och gå med i så många du vill. Du kan också lämna grupper när som helst. Utan att vara en del av en grupp kan du inte skapa evenemang.",
    teams_created_by_contacting_us:
      "För närvarande skapas grupper genom att kontakta oss. Vi arbetar på en lösning för att låta användare skapa grupper själva",
    your_teams_text: "Dina grupper",
    add_team_title: "Gruppnamn",
    add_team_helperText:
      "Detta kommer att vara namnet på din grupp. Du kommer automatiskt att vara ägare till denna grupp.",
    create_team_button: "Skapa grupp",
    add_team_email: "Gruppens e-post",
    add_team_email_helperText:
      "Detta kommer att vara gruppens e-post. Detta måste vara en giltig e-postadress.",
  },

  manager: {
    dashboard: {
      events: "Evenemang",
      events_description:
        "Här kan du se de evenemang av de grupper du är en del av.",
      no_events: "Det finns inga event att visa.",
      no_events_create: "Skapa ert första evenemang",
    },

    setup: {
      title: "Onboarding",
      description: "Slutför din profil för att börja skapa evenemang.",
    },

    teams: {
      title: "{{name}} Grupp",
      subtitle:
        "En grupp är organisatören av ett evenemang. Ett nätverk består av flera grupper som kan skapa evenemang. En nätverksadministratör kan skapa och lägga till användare i en grupp. Nedan kan du se alla grupper i ditt nätverk, klicka på en grupp för att se mer information.",
    },

    onboarding: {
      welcomeMessage:
        "Välkommen till tesseras setup och onboarding för arrangörerer!",
      intro:
        "Välkommen till setup-sidan för arrangörer. Som evenemangsarrangör är du bara några steg från att konfigurera ditt betalningssystem genom <1>Surfboard Payments</1>. Följ instruktionerna och fyll i nödvändiga uppgifter för att slutföra onboarding-processen.",
      stepByStepGuide: "Steg för steg guide för att slutföra onboardingen",
      steps: {
        step1: {
          title: "Fyll i företagsuppgifter",
          description:
            "Vänligen ange följande företagsuppgifter i formuläret nedan. Denna information kommer att användas för att skapa ditt handlarkonto och onlinebutik.",
        },
        step2: {
          title: "Fyll i KYB-formuläret",
          active: "Klicka på länken nedan för att fylla i KYB-formuläret.",
          description:
            "tessera kommer att generera en Know Your Business (KYB)-länk åt dig från Surfboard Payments partnerportal. Denna länk kommer att skickas till din registrerade e-postadress.",
        },
        step3: {
          title: "Verifieringsprocess",
          description:
            "När ni har skickat in dina uppgifter kommer Surfboard Payments att verifiera ert företags legitimitet. Denna process tar vanligtvis 2-4 arbetsdagar. Du kommer att få ett meddelande via e-post när dina företagsuppgifter har godkänts.",
        },
        step4: {
          title: "Kontoskapande",
          description:
            "Efter godkännande kommer Surfboard Payments automatiskt att skapa ett handlarkonto och en onlinebutik för ditt företag. Du kommer att få inloggningsuppgifter och relevant information för att komma åt ditt handlarkonto.",
        },
      },
      customization: {
        title: "Anpassning",
        description:
          "Introduktionswebbappen kan anpassas för att matcha Dow Technologies färger och varumärke. Om du vill anpassa utseendet på din introduktionssida, vänligen kontakta vårt supportteam för hjälp.",
      },
      importantLinks: {
        title: "Viktiga länkar",
        description:
          "Om du vill lära dig mer om Surfboard Payments, vänligen besök följande länkar:",
        links: {
          partnersGuideTitle: "Partners Guide",
          partnersGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/partners",

          merchantsGuideTitle: "Handlarnas Guide",
          merchantsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/merchants/merchants-home",

          storesGuideTitle: "Butiksguide",
          storesGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/stores/stores-home",

          terminalsGuideTitle: "Terminalguide",
          terminalsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/terminals/terminals-home",

          onboardingRequirementsTitle: "Krav för onboarding",
          onboardingRequirementsURL:
            "https://developers.surfboardpayments.com/docs/concepts/onboarding#requirements-for-onboarding",

          onlinePaymentsGuideTitle: "Guide för onlinebetalningar",
          onlinePaymentsGuideURL:
            "https://developers.surfboardpayments.com/docs/guides/online-payments",
        },
      },
      needHelp: {
        title: "Behöver du hjälp?",
        description:
          "Om du har några frågor eller behöver hjälp under onboardingen, vänligen kontakta oss via mejl på <1>info@tesseratickets.se</1>.",
      },
      thankYou: "Tack för att du väljer tessera!",
    },

    settings: {
      general: {
        title: "Allmänna inställningar",
        description:
          "Hantera utseendet och känslan av ditt nätverk, kanske har ni ett färgschema ni vill använda eller en logotyp ni vill ladda upp.",
        main_color: "Huvudfärg",
        accent_color: "Accentfärg",
        text_color: "Textfärg",
        pick_color: "Välj {{color}}",
        close_color_picker: "Stäng {{color}}-väljaren",
      },
      users: {
        title: "Nätverksanvändare",
        description:
          "Här kan du bjuda in användare till ditt nätverk genom att skicka en e-postinbjudan till dem. Du kan också hantera rollerna för användarna i ditt nätverk. Användare kan tilldelas rollerna Super Admin, Admin eller Member. När en användare accepterar en inbjudan får han eller hon tillgång till nätverket och kan läggas till i team inom nätverket för att börja skapa evenemang.",
        no_users: "Det finns inga användare i detta nätverk.",
        delete_network: "Radera nätverk",
        delete_network_confirmation:
          "Är du säker på att du vill radera detta nätverk? Denna åtgärd kan inte ångras.",
        delete_network_confirmation_title: "Bekräfta nätverksradering",
        edit_network: "Redigera nätverk",
        invite_user: "Bjud in användare",
        invite_user_helperText:
          "Ange e-postadressen till den användare du vill bjuda in.",
        user_added_success: "Användare tillagd framgångsrikt!",
        select_role_placeholder: "Välj roll",
        role_super_admin: "Superadministratör",
        role_admin: "Administratör",
        role_member: "Medlem",
        invite_pending: "Inbjudan väntar",
        no_pending_invites: "Det finns inga väntande inbjudningar.",
      },
      subscription: {
        title: "Prenumeration",
        description:
          "Hantera din prenumerationsplan. Du kan uppgradera din plan för att få fler funktioner.",
        current_plan: "Nuvarande Plan",
        upgrade_plan: "Uppgradera Plan",
        upgrade_plan_description:
          "I BETA är uppgradering av planer inte tillgänglig.",
        downgrade_plan: "Nedgradera Plan",
        cancel_plan: "Avbryt Plan",
        cancel_plan_confirmation:
          "Är du säker på att du vill avbryta din plan? Denna åtgärd kan inte ångras.",
        cancel_plan_confirmation_title: "Bekräfta Avbrytning av Plan",
        payment_plan: "Betalningsplan",
        enabled_features: "Aktiverade Funktioner",
        enabled_features_description:
          "I BETA är alla funktioner aktiverade, här är en lista över alla funktioner du kan använda.",
      },
      customization: {
        title: "Anpassa utseende",
        description:
          "Anpassa utseendet och känslan av ditt nätverk. Detta kommer att vara synligt när användare skapar evenemang.",
        background_color: "Bakgrundsfärg",
        background_color_helperText: "Välj en bakgrundsfärg för ditt nätverk.",
        brand_color: "Varumärkesfärg",
        brand_color_helperText: "Välj en varumärkesfärg för ditt nätverk.",
        accent_color: "Accentfärg",
        upload_logo: "Ladda upp logotyp",
        logo_helperText: "Ladda upp en logotyp för ditt nätverk.",
        upload_icon: "Ladda upp ikon",
        accent_color_helperText: "Välj en accentfärg för ditt nätverk.",
        rect_shape: "Rektangelform",
        rect_shape_helperText: "Välj formen på dina knappar.",
        rect_shapes: {
          rounded: "Rundad",
          edgy: "Kantig",
          pill: "Piller",
        },
        font_type: "Teckensnittstyp",
        font_type_helperText: "Välj teckensnittstyp för ditt nätverk.",
        font_types: {
          sans_serif: "Sans Serif",
          serif: "Serif",
          mono: "Monospace",
        },
        logo_url: "Logotyp URL",
        logo_url_helperText: "Ange URL för ditt nätverks logotyp.",
        icon_url: "Ikon URL",
        icon_url_helperText: "Ange URL för ditt nätverks ikon.",
        save_settings: "Spara inställningar",
        save_logo: "Spara logotyp",
        reset_settings: "Återställ inställningar",
      },
    },

    drawer: {
      title: "Nätverkspanel",
      events: "Evenemang",
      merchant_portal: "Handlarportal",
      all_events: "Alla evenemang",
      teams: "Grupp",
      create_event: "Skapa evenemang",
      settings: {
        title: "Inställningar",
        general: "Allmänt",
        subscription: "Prenumeration",
        financial: "Ekonomi",
        users: "Användare",
        customize: "Utseende",
      },
    },
  },

  features: {
    limit_description:
      "Du har överskridit gränsen för {{feature}}, uppgradera ditt abonnemang för att få mer.",
  },

  manage_event: {
    title: "Hantera {{event_name}}",
    delete_button: "Radera {{event_name}}",
    view_button: "Visa eventsida",
    manage_button: "Hanera",
    ticket_orders: "Biljettbeställningar",
    ticket: "Biljetter",
    manage_ticket_releases: "Hantera biljettsläpp",
    allocate_tickets_button: "Tilldela biljetter",
    allocated_tickets: "Tilldelade biljetter",
    ticket_release_method_title: "Biljettsläppsmetod",
    ticket_release_ticket_info_title: "Biljettinformation",
    lottery_entered_ticket_requests: "Önskades under lotterifönstret",
    not_lottery_entered_ticket_requests: "Önskades utanför lotterifönstret",
    ticket_release_actions_title: "Biljettsläppsåtgärder",
    paid_tickets: "Betalda biljetter",
    not_yet_paid_tickets: "Ännu inte betalda biljetter",
    remaining_tickets: "Återstående biljetter",
    refunded_tickets: "Återbetalade biljetter",
    not_open: "Inte öppnat än",
    not_yet_open: "Not yet open",
    closed: "har stängts",
    open: "är öppen",
    the_ticket_release: "Biljettsläppet",
    deleted_ticket_requests: "Raderade biljettförfrågningar",
    deleted_tickets: "Raderade biljetter",
    check_allocated_reserve_tickets: "Försök att tilldela reservbiljetter",
    check_allocated_reserve_tickets_tooltip:
      "Genom att trycka på denna knapp kommer du manuellt försöka tilldela reserverade biljetter. Detta är användbart om du inte vill vänta på den automatiska tilldelningen.",
    reserve_tickets: "Reserverade biljetter",
    pay_within_hours: "Användare måste betala inom (timmar)*",
    ticket_release_time_title: "Biljettsläppets öppen och stängningstid",
    edit_payment_deadline: "Redigera betaldeadline",
    payment_deadline: "Betalningsfrist",
    payment_deadline_description:
      "Här kan du ange betalningsfristen för ditt evenemang. Observera att detta inte kommer att påverka biljetter som redan har tilldelats, dvs biljetter som redan har en betalningsfrist, detta kommer endast att påverka biljetter som tilldelas efter denna ändring.",
    payment_deadline_not_editable:
      "Betalningsfristen kan inte redigeras för denna biljettsläpp. Betalningsfristen ställs in när du tilldelar biljetterna.",
    payment_deadline_helperText:
      "När ska användare som fått ordinarie biljetter betala?",
    reserve_payment_duration: "Reservbiljett betaldeadline",
    reserve_payment_duration_helperText:
      "Hur lång tid har andvändare som tidigare haft en reservbiljett på sig att betala för sin biljett innan den tilldelas till nästa person i kön? Format exempel: 7d 12h, 7 days, 12 hours.",
    reserve_payment_duration_text:
      "Reserverade biljetter som tilldelats kommer att ha en standard betalningsfrist på {{ days }} dagar, {{ hours }} timmar, {{ minutes }} minuter och {{ seconds }} sekunder.",
    allocate_tickets_confirm_title: "Bekräfta tilldelning av biljetter",
    allocate_tickets_warning:
      "Detta biljettsläpp är för närvarande öppet. Att tilldela biljetter nu kommer automatiskt att stänga biljettsläppet. Är du säker på att du vill tilldela biljetter nu?",
    allocate_tickets_confirm:
      "Är du säker på att du vill stänga detta biljettsläpp?",
    manage_tickets: "Hantera biljetter",
    tickets_available: "Biljetter tillgängliga",
    allocate_tickets_helperText:
      "Hur lång tid har användare på sig att betala för sina biljetter innan det ges till nästa person i kön?",
    delete_event_title: "Bekräfta radering av evenemang",
    delete_event_confirmation:
      "Är du säker på att du vill radera detta evenemang? Denna åtgärd kan inte ångras.",
    delete_event_confirmation_enter_text: "Skriv in 'delete' för att bekräfta.",
    manage_tickets_custom_event_form: "Anpassade evenemangsfält",
    add_ticket_release: "Lägg till biljettsläpp",

    delete_ticket_release_confirmation:
      "Är du säker på att du vill radera detta biljettsläpp? Denna åtgärd kan inte ångras.",

    ticket_releases: {
      title: "Hantera biljettsläpp",
    },

    breadcrumbs: {
      manage: "Hantera",
      edit: "Redigera",
      ticket_releases: "Biljettsläpp",
      event: "Evenemang",
      add_ons: "Tillägg",
      ticket_types: "Biljettbatcher",
      form: "Formulär",
      send_outs: "Utskick",
      tickets: "Biljetter",
      event_page: "Eventsida",
      emails: "E-post",
    },

    overview: {
      title: "Översikt",
      site_visits: "Webbplatsbesök",
      unique_visitors: "Unika Besökare",
      num_ticket_orders: "Antal Biljettbeställningar",
      total_income: "Total Inkomst",
      total_ticket_orders: "Totala Biljettbeställningar",
      total_paid_tickets: "Totalt Betalda Biljetter",
      total_refunded_tickets: "Totalt Återbetalda Biljetter",
      total_revenue: "Total Intäkt",
      total_remaining_tickets: "Totalt Kvarvarande Biljetter",
      revenue_per_ticket: "Intäkt per Biljett",
      event_capacity: "Evenemangskapacitet",
      abandoned_orders: "Övergivna Beställningar",
      time_to_payment_avg: "Genomsnittlig Betalningstid",
      event_is_at_capacity: "Evenemanget är fullsatt",
      tickets_remaining: "biljetter kvar",
      from_creation_to_payment: "Från skapande till betalning",
      total_payable_tickets: "Totalt betalbara biljetter",
    },

    no_data: "Ingen Data",
    settings: {
      financial: {
        title: "Finansiella inställningar",
        description:
          "Här kan du hantera de finansiella inställningarna för ditt evenemang. Du kan lägga till dina bankuppgifter och se den finansiella statusen för ditt evenemang.",
      },

      domain: {
        title: "Domäninställningar",
        description:
          "Här kan du hantera domäninställningarna för ditt evenemang.",
      },
    },

    drawer: {
      title: "Eventpanel",
      settings: {
        title: "Inställningar",
        financial: "Finansiell",
        emails: "E-post",
        domains: "Domäner",
      },

      send_outs: {
        title: "Utskick",
        list: "Lista",
        new: "Ny",
      },

      manage: {
        title: "Hantera",
        tickets: "Biljetter",
        ticket_releases: "Biljettsläpp",
        check_in: "Checka in",
        form_responses: "Formulärsvar",
        overview: "Översikt",
      },

      edit: {
        event: "Evenemang",
        ticket_releases: "Biljettsläpp",
        ticket_types: "Biljettbatcher",
        form: "Formulär",
        event_page: "Eventsida",
      },

      economy: {
        title: "Ekonomi",
        pay_outs: "Utbetalningar",
        sales_report: "Försäljningsrapport",
      },

      emails: {
        title: "E-post",
        templates: "Mallar",
      },

      is_not_pinned: "Fäst meny",
      is_pinned: "Lossa meny",
    },

    tickets: {
      title: "Hantera biljetter",

      user: {
        info: "Användarinfo",
        food_preferences: "Matpreferenser",
        full_name: "För- & efternamn",
        username: "Användarnamn",
        id: "ID",
        email: "E-post",
        phone: "Telefon",
        is_guest: "Är gäst",
      },

      ticket_info: {
        id: "ID",
        title: "Biljettinfo",
        ticket_batch: "Biljettgrupp",
        ticket_release: "Biljettsläpp",
        requested_at: "Begärd vid",
        is_paid: "Är betald",
        allocated: "Tilldelad",
        purchasable_at: "Kan köpas vid",
        payment_deadline: "Betalningsdeadline",
        entered_into_lottery: "Med i lotteri",
        is_reserve: "Reserv",
        paid_at: "Betald vid",
        checked_in: "Incheckad",
        deleted_at: "Raderad vid",
        is_deleted: "Är raderad",
        deleted_reason: "Anledning",
        ticket_type: "Biljettyp",

        ticket_types: {
          ticket: "Biljett",
          ticket_request: "Biljettförfrågan",
        },

        add_ons: {
          title: "Tillägg",
          name: "Namn",
          price: "Pris",
          quantity: "Antal",
          contains_alcohol: "Innehåller alkohol",
        },

        edit: {
          title: "Redigera biljettinfo",
          checked_in_helperText: "Har användaren checkat in?",
          payment_deadline_helperText:
            "Användaren kommer att få ett e-postmeddelande med den uppdaterade betalningsfristen.",
        },
      },

      event_form: {
        title: "Formulärsvar",
      },

      ticket_actions: {
        title: "Biljettåtgärder",
        allocate: "Tilldela",
      },

      payment_info: {
        title: "Betalningsinfo",
        currency: "Valuta",
        amount: "Belopp",
        refunded: "Återbetalad",
        refunded_at: "Återbetalad vid",
        payment_method: "Betalningsmetod",
      },
    },

    economy: {
      title: "Eventekonomi",
      subtitle:
        "Här kan du se ekonomin för ditt evenemang. Du kan generera försäljningsrapporter och se den totala intäkten för ditt evenemang.",
      sales_reports: "Försäljningsrapporter",
      total_sales: "Total försäljning",
      tickets_sold: "Biljetter sålda",
      created_at: "Skapad vid",
      status: "Status",
      message: "Meddelande",
      download: "Ladda ner",
      no_reports: "Det finns inga försäljningsrapporter för detta evenemang.",
    },

    private_event: {
      title: "Detta är ett privat evenemang",
      subtitle:
        "Detta evenemang är privat. Dela länken nedan med de personer du vill bjuda in till evenemanget.",
    },
    send_out: {
      title: "Utskick",
      new: "Nytt utskick",
      description:
        "Skicka ett e-postmeddelande till alla användare som har önskat en biljett till detta evenemang. Du kan använda detta för att meddela användare om biljettsläppet, eller för att meddela användare som har tilldelats en biljett.",
      preview: "Förhandsgranska",
      subject: "Ämne",
      subject_helperText: "Vad är ämnet för e-postmeddelandet?",
      message: "Meddelande",
      message_helperText:
        "Vad är innehållet i e-postmeddelandet? Markdown stöds.",
      preview_helperText:
        "Så här kommer e-postmeddelandet att se ut för användarna.",
      ticket_releases: "Välj biljettsläpp",
      ticket_releases_helperText:
        "Användarna av de valda biljettsläppen kommer att få e-postmeddelandet.",
      filter_tickets: "Filtrera biljetter",
      filter_tickets_helperText:
        "Filtrera biljetter baserat på deras status. Du kan välja flera statusar.",
      num_users:
        "E-postmeddelandet kommer att skickas till {{numUsers}} användare.",
      recipients: "Mottagare",
      status_message: "Statusmeddelande",
      no_send_outs: "Det finns inga utskick för detta evenemang.",
    },
    edit: {
      title: "Redigera evenemang",
      subtitle: "Här kan du redigera alla detaljer om ditt evenemang.",
      event_details: {
        title: "Redigera evenemangsdetaljer",
      },
      ticket_releases: {
        title: "Redigera biljettsläpp",
        edit_name: "Redigera {{name}}",
        subtitle:
          "Skapa biljettsläpp för ditt evenemang. Du kan skapa så många du vill, och du kan redigera dem senare.",
        select: "Välj ett biljettsläpp för att redigera det.",
        add: "Lägg till biljettsläpp",
        closed: "Stängt",
        add_subtitle:
          "Låt oss lägga till ett annat biljettsläpp. Först måste vi definiera detaljerna för detta biljettsläpp.",
        no_ticket_releases: "Det finns inga biljettsläpp för detta evenemang.",
        edit_ticket_types: "Redigera biljetter",
        add_helperText: "Vänligen välj ett biljettsläpp att redigera.",

        edit_add_ons: "Redigera tillägg",
      },
      add_ons: {
        title: "Redigera biljettillägg",
        subtitle:
          "Lägg till tillägg till biljettsläppet. Du kan skapa så många du vill. Försök att vara specifik med namnet och beskrivningen av tillägget. Du kan också ange om tillägget kostar extra.",
        form_title: "Formulär för tillägg",
        form_subtitle:
          "Definiera dina tillägg här. Du kan lägga till så många du vill.",
        confirm_delete_title: "Bekräfta radering av tillägg",
        confirm_delete_text:
          "Är du säker på att du vill ta bort detta tillägg? Denna åtgärd kan inte ångras.",
      },

      ticket_types: {
        title: "Redigera biljettbatcher",
        ticket_details: "Biljettinformation",
        ticket_details_helperText:
          "Ändra detaljerna för dina biljettyper och klicka sedan på 'Spara'.",
      },

      event_page: {
        title: "Redigera eventsida",
        description:
          "Välj mellan en enkel eventsida eller anpassa den efter dina önskemål.",
        enable: "Använd anpassad eventsida",
        enabled: "Aktiverad",
        disabled: "Inaktiverad",
        editor_title: "Redigeraren",
        editor_description:
          "Eventsidans redigerare är ett kraftfullt verktyg som hjälper dig att skapa en anpassad eventsida för ditt evenemang. Du kan lägga till text, bilder, videoklipp och mer. Du designar sidan precis som du vill att den ska se ut, och vi tar hand om resten.",
        editor_button: "Gå till redigeraren",
      },
    },

    form_field_responses: {
      list_view: "Listvy",
      table_view: "Tabellvy",
    },
  },

  event_form_fields: {
    title: "Evenemangsformulär",
    description:
      "Evenemangsarrangören har begärt ytterligare information från dig. Se information från evenemangsarrangören nedan.",
    accept_terms_and_conditions:
      "Genom att skicka in detta formulär godkänner du att dela ovanstående information med evenemangsarrangören för att de ska kunna planera evenemanget. Informationen kommer att behandlas i enlighet med kapitlens informationsbehandlingspolicy.",
    no_form_fields: "Oj då! Det finns inget formulär för detta evenemang.",
    ticket_number: "Biljett {{number}}",
    ticket_number_description: "Detaljer för biljett {{number}} fylls i här.",
  },

  event: {
    list_title: "Evenemang",
    tickets: "Biljetter",
    reserved: "Reserverad",
    promo_code_title: "Promokod",
    ticket_releases: "Biljettsläpp",
    event_by: "Evenemang av",

    no_ticket_releases: "Det finns inga biljettsläpp för detta evenemang.",
    promo_code_helperText:
      "Ange promokoden för att få tillgång till reserverade biljetter.",
    contact_organizers:
      "Om du har några frågor, kan du kontakta <1>{{organization}}</1> <2>här</2>.",
    ticket_request_success_title: "Biljettförfrågan skickad!",
    ticket_request_success_description:
      "Du kan också fylla i denna information senare om du vill. Du kan göra det <1>här</1>.",
    ticket_releases_description:
      "Här kan du se alla biljettsläpp för eventet. Du kan begära biljetter för varje biljettsläpp. Om du har en kampanjkod, bläddra till botten av vyn för att ange den.",
    ticket_order_pay_direct_form_later:
      "Du kan fylla i formuläret efter att du har betalat för din biljett.",
    continue_pay: "Fortätta till betalning",
    continue_ticket_orders: "Visa biljettförfrågan",
    check_in: {
      scan_ticket_title: "Scanna biljett",
      scan_ticket_instructions: "Skanna QR-koden för att checka in.",
      loading: "Laddar...",
      manual_check_in_button: "check-in",
      search_tickets: "Sök efter biljett",
      checked_in: "Incheckad",
      hide_checked_in: "Dölj incheckade",
      search_tickets_helperText: "Skriv in namn, e-post eller biljett ID.",
      ready_to_scan: "Redo att scanna",
      no_camera_permission: "Kameraåtkomst nekad",
    },
    ticket_release: {
      closed: "Biljettsläppet har stängts",
      tickets_available_in: "Biljetter tillgängliga om",
      tickets_available_for: "Biljettsläppet stänger om",
      method: "Detta biljettsläpp använder <1>{{method}}</1>",
      sold_out: "Slutsålt",
      second: "sekund(er)",
      minute: "minut(er)",
      hour: "timme(ar)",
      day: "dag(ar)",
      week: "vecka(or)",
      month: "månad(er)",
      method_info: {
        fcfsl:
          "Förfrågningar som görs inom de första <1>{{duration}}</1> minuter av biljettsläppet kommer att delta i lotteriet.",
      },
      remove_reminder: "Ta bort påminnelse",
      set_reminder: "Sätt påminnelse 10 minuter innan biljettsläppet öppnar",
      reserved: "Reserverad",
      no_tickets: "Det finns inga biljetter tillgängliga.",
      information_processing_policy_info:
        "Genom att begära/köpa en biljett samtycker du till att dela dina uppgifter med evenemangsarrangören i enlighet med tesseras <1>Privacy Policy</1>, du samtycker också till tesseras <2>Refund Policy</2> och <3>Terms & Conditions</3>",
      checkout: {
        title: "Biljettdetaljer",
        overview: "Översikt",
        what_is_a_request_title: "Vad är en biljettförfrågan?",
        what_is_a_request:
          "När du gör en biljettförfrågan är du inte garanterad att få de biljetter du vill ha. Tilldelningen av biljetterna görs enligt metoden för biljettsläpp, som beskrivs i biljettsläppbeskrivningen.",
        total: "Totalt",
        ticket: "Biljett",
      },
      add_ons: {
        title: "Biljettillägg",
        description: "Här kan du lägga till tillägg till din biljett.",
        max_quantity: "Max kvantitet",
        contains_alcohol: "Innehåller alkohol",
        view_add_ons: "Visa biljettillägg",
        ticket_number: "Biljett nummer",
      },
      request_process: {
        complete_ticket_request: "Slutför önska biljetter",
        account_required_description:
          "För att begära en biljett till detta evenemang måste du ha ett konto. Vänligen logga in eller skapa ett konto.",
        already_have_an_account: "Har du redan ett konto?",
        continue_as_guest: "Fortsätt som gäst",
        form: {
          first_name: "Förnamn",
          last_name: "Efternamn",
          email: "E-post",
          phone_number: "Telefonnummer (valfritt)",
          password: "Lösenord",
          password_repeat: "Upprepa lösenord",
          button_save_account: "Spara konto",
          button_save_account_helperText:
            "Tessera sparar dina uppgifter och skapar ett konto åt dig.",
          button_sign_in: "Logga in",
          button_sign_up: "Registrera dig",
          button_continue_as_guest: "Fortsätt som gäst",
        },
      },
    },
  },

  ticket_request: {
    cost_overview: "Prisöversikt",
    cancel_ticket_request_button: "Avbryt",
    go_to_tickets_button: "Gå till biljetter",
    cancel_ticket_request_confirm_title:
      "Bekräfta avbrytande av biljettförfrågan",
    handled: "Biljettförfrågan konverterad till biljett",
    deleted: "Raderad biljettförfrågan",
    ticket_request: "Biljettförfrågan",
    cancel_ticket_request_confirm:
      "Är du säker på att du vill avbryta denna biljettförfrågan? Denna åtgärd kan inte ångras.",
  },

  ticket_release_method: {
    first_come_first_served_title: "Först till kvarn",
    first_come_first_served_description:
      "Först till kvarn-lotteriet är en metod för biljettsläpp där personer som önskar en biljett inom en angiven tidsram deltar i ett lotteri. När biljetter tilldelas, placeras alla biljettförfråganden inom denna tidsram i ett lotteri och vinnarna väljs slumpmässigt. Vinnarna får en biljett och resten placeras på väntelistan. Alla som önskar en biljett efter den angivna tidsramen placeras på väntelistan, om inte lotteriet är ofullständigt. Om lotteriet inte är fullt, ges de återstående biljetterna till personerna på väntelistan i den ordning de önskade biljetten.",
  },

  tickets: {
    cost_overview: "Prisöversikt",
    confirm_cancel_ticket_title: "Bekräfta borttagande av biljett",
    confirmed_ticket:
      "Din biljett har bekräftats! Nu är det dags att betala för din biljett. Du kan betala för din biljett genom att klicka på knappen nedan. Om du inte betalar för din biljett före <1>{{payBefore}}</1> kommer din biljett att ges till nästa person i kön.",
    reserve_ticket:
      "Tyvärr fick du en reservbiljett till detta evenemang. Du kommer att meddelas om en biljett blir tillgänglig.",
    has_paid: "Du har betalat för din biljett!",
    has_refunded: "Din biljett har återbetalats.",
    request_refund:
      "Du kan begära en återbetalning genom att kontakta arrangören.",
    no_refund: "Tyvärr, det finns ingen återbetalning för denna biljett.",

    cancel_ticket_request_button: "Avbryt",
    cancel_ticket_button: "Jag vill inte längre delta",
    confirm_cancel_ticket_request_title:
      "Bekräfta avbrytande av biljettförfrågan",
    reserve_number: "Du har plats <1>{{number}}</1> på reservlistan.",
    leave_reserve_list_text: "Lämna reservlistan",
    paid_ticket:
      "Du har betalat för din biljett! Vi ser fram emot att se dig på evenemanget. Kvittot kommer att skickas till din e-post.",
    confirm_cancel_reserve_ticket_text:
      "Är du säker på att du vill avbryta din biljett? Du kommer inte att kunna få tillbaka din biljett och denna åtgärd kan inte ångras!",
    confirm_cancel_ticket_text:
      "Är du säker på att du vill lämna reservlistan och avbryta din biljett? Du kommer inte att kunna få tillbaka din biljett och denna åtgärd kan inte ångras!",
    pay_button: "Betala",
    not_paid_on_time:
      "Du har inte betalat för din biljett i tid, därför har din biljett gått till nästa person i kön.",
    total_cost: "Totalt",
    payment: {
      title: "Bekräfta ditt biljettköp",
      pay_now: "Betala {{price}} SEK",
      description: "Här kan du betala för din biljett.",
    },
    qr_code: {
      description:
        "Detta är din QR-kod. Var vänlig ha den redo när du anländer till evenemanget.",
      already_checked_in: "Du har redan checkat in.",
    },
  },

  customer: {
    login: "Logga in",
    signup: "Registrera dig",
    oauth: "Du kan också logga in med",
    info: {
      subtitle: "Extern inloggning och registrering.",
      description:
        "Välkommen till tessera! Genom att skapa ett konto samlas all din information på ett ställe. Begär enkelt biljetter, hantera dina biljetter och mer. Obs: Du behöver inte ett konto för att begära biljetter till vissa evenemang.",
      forgot_password: "Glömt lösenord?",
      dont_have_an_account: "Har du inget konto? Registrera dig!",
    },
    form: {
      first_name: "Förnamn",
      last_name: "Efternamn",
      username: "Användarnamn",
      email: "E-post",
      password: "Lösenord",
      password_repeat: "Upprepa lösenord",
      button_signup: "Registrera dig",
      button_login: "Logga in",
      no_account: "Har du inget konto? Vänligen kontakta oss.", // TODO add email
    },
    accept_terms: {
      first: "Jag godkänner ",
      privacy_policy: "Integritetspolicyn",
      terms_of_use: "Villkoren",
      acceptable_use_policy: "Användarvillkoren",
      cookie_policy: "Cookiepolicyn",
    },
    forgot_password: {
      title: "Begär nytt lösenord",
      description:
        "Ange e-postadressen som är kopplad till ditt konto för att få en länk för att återställa ditt lösenord.",
    },

    wait_verify_email: {
      title: "Verifiera din e-postadress",
      description:
        "Vi har skickat en verifieringslänk till din e-postadress. Klicka på länken för att verifiera din e-postadress.",
    },

    resend: {
      title: "Skicka verifieringslänk",
      toast_description:
        "Det verkar som att du inte har verifierat din e-postadress.",
      toast_button: "Skicka igen",
      description:
        "Om du inte har fått ett e-postmeddelande, klicka på knappen nedan för att skicka en ny verifieringslänk.",
    },
  },

  common: {
    back: "Tillbaka",
    cancel: "Avbryt",
    show_all: "Visa alla",
    show_less: "Visa mindre",
    search: "Sök",
    created: "Skapad",
    made_at: "Gjord",
    updated: "Uppdaterad",
    learn_more: "Läs mer",
    private_event: "Privat evenemang",
    mobile_warning:
      "Välkommen till tessera! Vi ser att du använder en mobil enhet. Vissa delar av webbplatsen kanske inte är optimerade för mobila enheter. Men att begära och visa biljetter bör fungera som förväntat. Om du är en evenemangsarrangör rekommenderar vi att du använder en skrivbordsenhet.",
    show_more: "Visa mer",
    hour_one: "timme",
    hour_other: "timmar",
    minute_one: "minut",
    minute_other: "minuter",
    of: "av",
    days_ago: "dagar sedan",
    since: "sedan",
    lets_start: "Låt oss börja",
  },

  footer: {
    about_title: "Om",
    about_content:
      "tessera är en plattform som gör biljettsläpp och biljettadministration enkelt.",
    quick_links_title: "Snabblänkar",
    home: "Hem",
    events: "Evenemang",
    profile: "Profil",
    report_an_issue_title: "Rapportera ett problem",
    made_by: "tessera är byggt av <1>Lucas Dow</1>.",
    report_an_issue_content:
      "Om något inte fungerar, eller om du har ett förslag, kan du <1>Skapa ett problem på Github</1>.",
    contact_title: "Kontakt",
    follow_us: "Följ oss",
    legal_title: "Juridiskt",
  },

  book_demo: {
    button_text: "Boka demo",
    title: "Boka en demo",
    description:
      "Fyll i formuläret nedan för att boka en demo. Vi kommer att kontakta dig inom kort för att schemalägga en tid.",
    name: "Namn",
    name_helperText: "Vad heter du?",
    email: "E-post",
    email_helperText: "Vad är din e-postadress?",
    phone: "Telefon",
    phone_helperText: "Vad är ditt telefonnummer?",
  },

  main_page: {
    phrases: {
      "1": "Biljettsläpp ska inte vara jobbiga!",
      "2": "Biljettsläpp ska inte vara knepigt!",
      "3": "Biljettsläpp ska inte vara krångliga!",
      "4": "Eventhantering ska vara lätt!",
      "5": "Eventhantering ska vara enkelt!",
      "6": "Eventhantering ska vara rakt på sak!",
    },
    not_a_pain: "Biljettsläpp ska inte vara jobbiga!",
    welcome: "{{name}}, välkommen till",
    learn_how_button: "Lär dig hur",
    get_in_touch_button: "Kontakta",
    key_features: {
      title: "Varför tessera?",
      feature1: {
        title: "Innovativ Biljettfördelning",
        description:
          "tessera erbjuder flexibla biljettalternativ, lotterier, först-till-kvarn-metoder samt tradionell biljettförsäljning. Detta säkerställer en rättvis och transparent fördelning, oavsett evenemangets storlek.",
      },
      feature2: {
        title: "Enkel Evenemangshantering",
        description:
          "Att skapa och hantera evenemang har aldrig varit enklare. Med tesseras effektiva system kan du anpassa ditt evenemang till dina specifikationer, släppa biljetter och hantera deltagare med bara några få klick.",
      },
      feature3: {
        title: "Fullt Anpassningsbara Evenemangssidor",
        description:
          "Skapa vackra, varumärkesanpassade evenemangssidor med tesseras anpassningsverktyg. Justera färger, logotyper och layouter för att få ditt evenemang att sticka ut och återspegla din unika identitet.",
      },
    },
    social_proof: {
      title: "Vad Våra Kunder Säger",
      cta: "Registrera Dig Nu!",
      ready: "Är du redo att komma igång?",
      description:
        "Registrera dig idag och börja använda vår gratisplan, eller boka en demo för att lära dig mer om våra premiumplaner.",
    },
    login_page: {
      secure_spot: "För att säkra din plats.",
      for_business_inquiries:
        "För företagsrelaterade förfrågningar, vänligen kontakta <1>Lucas Dow</1>, skaparen av tessera",
    },
    page_description: {
      what_title: "Vad är tessera?",
      what: "tessera är en plattform som gör biljettförsäljning enkel. Att köpa\
            biljetter ska inte vara svårt! tessera gör det enkelt att skapa\
            biljettsläpp och hantera dem. Evenemangsarrangörer kan enkelt\
            se dina matpreferenser, allergier och mer för att göra din upplevelse\
            så trevlig som möjligt, utan krångel. Ditt konto är automatiskt knutet\
            till dina biljetter, så det finns inget behov av att konstant fylla i google-formulär\
            eller något sådant.",
      in_beta_title: "tessera BETA",
      in_beta:
        "tessera är just nu i BETA. Detta innebär att tessera fortfarande\
        utvecklas och förbättras. Om du eller din organisation är intresserade\
        av att använda tessera BETA kan vi sätta upp en grupp för dig kostnadsfritt. På detta sätt kan du skapa\
        evenemang och hantera biljettsläpp. Samtidigt kan du ge feedback\
        och hjälpa oss att förbättra tessera. Ta kontakt för att komma igång!",
      how_title: "Hur fungerar det?",
      how: "För att skapa ett biljettsläpp till ditt evenemang måste du först bli en evenemangsarrangör genom att skapa ett team. För närvarande skapas team genom att kontakta oss. När du har skapat ett team kan du börja skapa och hantera biljettsläpp. Du kan bjuda in andra användare till ditt team så att de kan hjälpa till att hantera biljettsläppen. Som användare kan du se offentliga evenemang i evenemangsfliken. tessera fungerar inte som andra biljettplattformar där du måste betala för en biljett direkt. tessera erbjuder olika sätt att distribuera biljetter på, såsom ett lotterisystem eller ett först till kvarn system. Därför köper inte en användare en biljett, utan önskar (Request) en biljett istället. När biljettsläppet stänger används den valda allokationsmetoden för att distribuera biljetter till användarna. Om du har några frågor, tveka inte att kontakta oss.",
    },
    get_in_touch: {
      call_to_action: "Ta kontakt",
      quote1:
        "Jag hade egentligen inte tänkt att tessera skulle lyckas men efter en hel del arbete och slit så blev det ändå något som fungerade. Det är oerhört kul att tessera används av andra också! ",
      quote2:
        "Om din organisation eller ditt team letar efter ett lättanvänt evenemangs- och biljettadministrationssystem, kontakta mig.",
    },
    how_to_use: {
      request_ticket_title: "Önska en biljett",
      request_ticket:
        "tesserars nyskapande sätt att dela ut biljetter gör det möjligt för användare att få biljetter till evenemang genom olika metoder, som ett lotteri eller på en först-till-kvarn. Detta gör att alla har en rättvis chans att få biljetter. För att önska en biljett, gå bara till evenemangsfliken, sök upp ditt evenemang och klicka på 'Önska Biljett'.",
      fill_out_title: "Fyll i personuppgifter",
      fill_out:
        "När du har önskat en biljett behöver du fylla i personliga uppgifter, som dina matpreferenser och eventuella allergier. Detta gör att arrangörerna kan skräddarsy evenemanget för dig och säkerställer en mer personlig upplevelse. tessera kopplar ditt konto till dina biljetter, vilket gör processen smidigare och hjälper dig att njuta mer av evenemanget.",
      wait_title: "Vänta på din biljett",
      wait: "Det sista steget är att vänta på att biljettsläppet stänger och biljetterna delas ut, antingen genom ett lotteri eller på en först-till-kvarn-basis. tessera ser till att biljetterna fördelas rättvist. Håll ett öga på din e-post eller ditt tessera-konto för att få uppdateringar om din begärans status. Om du får biljetter kommer du även att få vidare instruktioner, som hur du betalar.",
    },
  },

  product: {
    titles: {
      ticket_distribution: "Biljettfördelning",
      event_management: "Eventhantering",
      event_pages: "Eventsidor",
      team_management: "Teamhantering",
      beta_program: "BETA-program",
      manager_onboarding: "Onboarding för arrangörer",
      event_forms: "Eventformulär",
      data_security: "Datasäkerhet",
      payments: "Betalningar",
    },
    main: {
      more_features: "Fler funktioner",
      title: "Upptäck tessera's Funktioner",
      description:
        "Utforska hur tessera kan förändra din upplevelse av evenemangshantering.",
      about_title: "Vad är tessera?",
      about_description:
        "tessera är en innovativ plattform designad för att förenkla evenemangshantering, biljettfördelning och deltagarengagemang. Med en uppsättning kraftfulla funktioner ger tessera evenemangsarrangörer möjlighet att skapa sömlösa och minnesvärda upplevelser.",
      about_description_2:
        "tessera håller sina arrangörer och deltagare i åtanke och erbjuder en användarvänlig plattform som effektiviserar planeringsprocessen för evenemang. Med tessera BETA kan du påverka framtiden för evenemangshantering genom att ge feedback och förslag till vårt team. All typ av feedback är välkommen! Vill du ha en ny funktion? Låt oss veta!",
      features: {
        title: "Nyckelfunktioner",
        more_coming_soon:
          "...och många fler som vi är glada att snart kunna dela med dig!",
      },
    },
    ticket_distribution: {
      title: "Biljettfördelning",
      description:
        "Flexibla och rättvisa biljettfördelningsmetoder för evenemang av alla storlekar",
      section_1: {
        title: "Innovativ biljettfördelning",
        description:
          "tessera erbjuder flexibla biljettalternativ, inklusive lotterier och först till kvarn-metoder. Detta säkerställer en rättvis och transparent fördelning, oavsett evenemangets storlek.",
        description_2:
          "Om du letar efter ett anpassningsbart biljettdistributionssystem är tessera rätt val för dig. Låt oss diskutera dina behov och hitta den bästa lösningen för ditt evenemang.",

        cards: {
          card_1: {
            title: "Lotterier",
            description:
              "Skapa en lotteridragning för biljettsläpp för att säkerställa en rättvis och transparent fördelning av biljetter.",
          },
          card_2: {
            title: "Först till kvarn",
            description:
              "Använd först till kvarn-metoden för att distribuera biljetter till de snabbaste användarna.",
          },
          card_3: {
            title: "Reserverade biljettsläpp",
            description:
              "Skapa reserverade biljettsläpp med kampanjkoder för specifika användare.",
          },
          card_4: {
            title: "Anpassningsbar biljettfördelning",
            description:
              "Anpassa biljettdistributionsmetoder för att passa ditt evenemangs behov.",
          },
          card_5: {
            title: "Traditionella förskottsbetalningar",
            description:
              "Vi erbjuder en traditionell förskottsköpsmetod för biljettsläpp.",
          },
        },
      },
      section_2: {
        title: "För att det är rättvist",
        description:
          "tessera erbjuder ett rättvist och transparent biljettdistributionssystem. Evenemang där många människor är intresserade av att delta kan vara svåra att hantera. tesseras innovativa biljettdistributionsmetoder säkerställer att alla har en lika stor chans att få en biljett.",
        description_2:
          "Traditionella biljettdistributionsmetoder såsom förskottsbetalningar är också tillgängliga. tessera erbjuder en mängd olika biljettdistributionsmetoder för att passa ditt evenemangs behov.",
      },
    },
    event_management: {
      title: "Eventhantering",
      description: "Enkel eventhantering för alla dina evenemang",
      section_1: {
        title: "Enkel eventhantering",
        description:
          "Att skapa och hantera evenemang har aldrig varit enklare. Med tesseras strömlinjeformade system kan du ställa in evenemang, släppa biljetter och hantera deltagare med bara några få klick.",
        description_2:
          "tessera erbjuder en rad funktioner för att hjälpa dig hantera ditt evenemang, från att skapa biljettsläpp till att spåra deltagare. Låt oss hjälpa dig att göra ditt evenemang till en framgång.",
        description_3:
          "Definiera när ditt evenemang börjar och när det slutar. Skapa ett biljettsläpp och räkna ned till släppet tillsammans med dina användare. Definiera biljettyper och hur de distribueras. Lägg till anpassade fält till ditt evenemangsformulär för att samla in ytterligare information från dina användare.",
      },
      section_2: {
        title: "För att det är enkelt",
        description:
          "tessera förenklar eventhantering genom att erbjuda en användarvänlig plattform för att skapa och hantera evenemang. Med tessera kan du enkelt ställa in biljettsläpp, hantera deltagare och spåra biljettförsäljning.",
        description_2:
          "Vår plattform är utformad för att göra eventhantering så enkel och okomplicerad som möjligt. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
    },
    customizable_event_pages: {
      title: "Anpassningsbara eventsidor",
      description:
        "Skapa vackra, varumärkesanpassade eventsidor för att visa upp ditt evenemang",
      section_1: {
        title: "Fullt anpassningsbara eventsidor",
        description:
          "Skapa vackra, varumärkesanpassade eventsidor med tesseras integrerade sidbyggare. Anpassa layout, färger och innehåll för att skapa en unik eventsida som återspeglar ditt varumärke. Använd våra anpassade komponenter eller lägg till din egen HTML och CSS.",
        description_2:
          "Med tesseras integrerade sidbyggare kan du säkerställa att inga två eventsidor ser likadana ut. Låt oss hjälpa dig att skapa en eventsida som sticker ut.",
      },
      section_2: {
        title: "För att det är enkelt",
        description:
          "Ange bara användarens e-post och tilldela dem en roll så får de en inbjudan att gå med i din organisation. När de har accepterat inbjudan kan du tilldela dem till olika team och börja samarbeta kring evenemang.",
        description_2:
          "Vår plattform är utformad för att vara enkel att använda, så att du kan skapa en vacker eventsida utan teknisk expertis. Låt oss hjälpa dig att skapa en eventsida som sticker ut.",
      },
    },
    team_management: {
      title: "Teamhantering",
      description: "Hantera dina team enkelt och samarbeta kring evenemang",
      section_1: {
        title: "Enkel teamhantering",
        description:
          "tessera gör det enkelt att hantera team och samarbeta kring evenemang. När du ställer in din organisation kan du skapa team för olika evenemang och tilldela roller till teammedlemmar. Detta säkerställer att alla är på samma sida och arbetar mot ett gemensamt mål.",
        description_2:
          "Bjud in medlemmar att gå med i din organisation och tilldela dem till team. Definiera roller och behörigheter för att säkerställa att alla har rätt åtkomstnivå. Låt oss hjälpa dig att hantera dina team och samarbeta kring evenemang. Låt oss ställa in din organisation så att du kan börja bjuda in medlemmar och skapa team idag!",
      },
      section_2: {
        title: "För samarbete är viktigt",
        description:
          "tessera är utformad för att vara en samarbetsplattform för eventplanering. Med tessera kan du skapa team, lägga till medlemmar och tilldela roller för att effektivisera din eventplaneringsprocess.",
        description_2:
          "Vår plattform är utformad för att göra det enkelt för team att samarbeta kring eventplanering. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
    },
    beta_program: {
      title: "tessera BETA",
      description:
        "Gå med i tessera BETA-programmet och hjälp till att forma framtidens eventhantering",
      section_1: {
        title: "tessera BETA",
        description:
          "tessera är för närvarande i BETA, vilket innebär att det fortfarande utvecklas och förbättras. Om din organisation är intresserad av att använda tessera BETA kommer vi att ställa in ett team åt dig utan kostnad. Du kommer att ha tillgång till alla funktioner i tessera, inklusive biljettdistribution, eventhantering och teamsamarbete.",
        description_2:
          "Gå med i tessera BETA-programmet och hjälp till att forma framtidens eventhantering. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
      section_2: {
        title: "För att det är innovativt",
        description:
          "tessera är en innovativ plattform som ständigt utvecklas för att möta behoven hos eventarrangörer. Med tessera BETA kan du hjälpa till att forma framtidens eventhantering genom att ge feedback och förslag till vårt team.",
        description_2:
          "Vår plattform är utformad för att vara innovativ och framåtblickande, så att du kan skapa framgångsrika evenemang som möter dina deltagares behov.",
      },
    },
    secure_payment: {
      title: "Säker betalningshantering",
      description: "Integrera säkra betalningar sömlöst i dina evenemang",
      section_1: {
        title: "Integrerade betalningar",
        description:
          "Bearbeta biljettbetalningar säkert inom tesseras plattform. tessera samarbetar med Surfboard Payments för att ge dina deltagare en sömlös betalningsupplevelse.",
        description_2:
          "Onlinekassor har aldrig varit enklare. Betala med antingen kredit-/betalkort eller Swish. tessera ger dina deltagare en säker betalningsupplevelse.",
        description_3: "Läs mer om Surfboard Payments",
      },
      section_2: {
        title: "För att det är viktigt",
        description:
          "tesseras plattform säkerställer att alla betalningar bearbetas säkert, med robust återbetalningshantering för att göra hanteringen av betalningar stressfri.",
        description_2:
          "Med tessera kan du hantera återbetalningar direkt från plattformen, vilket förenklar eventhantering och ger en sömlös upplevelse för deltagarna.",
      },
    },
    custom_event_forms: {
      title: "Anpassade eventformulär",
      description:
        "Samla in anpassad deltagarinformation för att personanpassa din eventupplevelse",
      section_1: {
        title: "Samla in anpassad information",
        description:
          "tesseras anpassningsbara formulärbyggare gör att du kan samla in deltagarinformation som är specifik för ditt evenemang. Oavsett om det är frågor eller speciella önskemål hjälper tessera dig att samla in rätt information. Denna information lagras säkert och kan nås när som helst.",
        description_2:
          "Vår lättanvända formulärbyggare låter dig samla in information av olika typer, inklusive text, siffror, flervalsalternativ, med möjlighet att lägga till anpassade fält. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
      section_2: {
        title: "För att det är flexibelt",
        description:
          "tessera tillhandahåller ett flexibelt datahanteringssystem som säkert lagrar all information du samlar in. Med integritet och datasäkerhet i åtanke säkerställer tessera att all deltagardata hanteras säkert.",
        description_2:
          "tesseras plattform är GDPR-kompatibel, vilket säkerställer att ditt evenemang och dina deltagares data skyddas enligt de högsta standarderna.",
      },
    },
    privacy_security: {
      title: "Integritet och datasäkerhet",
      description:
        "Skyddar användardata med GDPR-efterlevnad och robusta säkerhetsåtgärder",
      section_1: {
        title: "GDPR-efterlevnad",
        description:
          "tessera är engagerad i att skydda användardata i enlighet med GDPR-reglerna. Vi säkerställer att all personlig information hanteras transparent och säkert, vilket ger dina deltagare sinnesro.",
        description_2:
          "Vår plattform tillhandahåller tydliga integritetspolicyer som informerar användare om hur deras data används, lagras och skyddas.",
      },
      section_2: {
        title: "För att det är säkert",
        description:
          "tessera använder robusta säkerhetsåtgärder för att säkerställa att all deltagarinformation hålls säker. Vi använder kryptering och säkra datahanteringsmetoder för att skydda mot obehörig åtkomst.",
        description_2:
          "Med tessera kan du fokusera på att skapa fantastiska evenemang, med vetskap om att dina deltagares data är säkra och deras integritet respekteras.",
      },
    },
    manager_onboarding: {
      title: "Onboarding för arrangörer",
      description: "Kom igång snabbt med enkel onboarding och setup",
      section_1: {
        title: "Enkel onboarding",
        description:
          "tessera tillhandahåller en enkel onboarding-process för eventarrangörer. Skapa ett konto, ställ in din organisation, fyll i dina företagsuppgifter och börja sälja biljetter på nolltid.",
        description_2:
          "Onboarding-verifieringsprocessen tar 1-4 dagar. När ditt konto är verifierat kan du börja skapa evenemang och hantera biljettsläpp.",
      },
      section_2: {
        title: "För att det är nödvändigt",
        description:
          "tessera säkerställer att alla dess arrangörer är legitima företag. Detta hjälper till att skydda deltagare och säkerställa att evenemang drivs professionellt.",
        description_2:
          "Vår plattform är utformad för att göra onboarding så enkel som möjligt.",
        description_3_logged_out:
          "Låt oss börja med att skapa ett konto och registrera ditt företag!.",
        description_3_logged_in:
          "Om du inte redan har valt en plan kan du göra det nu. När du har valt en plan kommer onboarding-processen att guida dig genom de följande stegen!",
        steps: {
          step_1: {
            number: "01",
            label: "Registrera dig",
          },
          step_2: {
            number: "02",
            label: "Registrera företag",
          },
          step_3: {
            number: "03",
            label: "Fyll i detaljer",
          },
          step_4: {
            number: "04",
            label: "Klar!",
          },
        },
      },
    },
  },

  refund: {
    dialog_title: "Återbetala biljett",
    reason: "Anledning till återbetalning",
    amount: "Återbetalningsbelopp",
    payment_method: "Betalningsmetod",
    submit: "Genomför återbetalning",
    ticket_info: "Biljett-ID: {{id}}, Typ: {{type}}, Pris: {{price}}",
    user_info: "Användare: {{name}}, E-post: {{email}}",
    cannot_refund: "Kan inte återbetala",
  },
  error: {
    no_rows_selected: "Inga rader valda",
    invalid_refund_selection:
      "Ogiltig återbetalningsval. Vänligen välj en enskild biljett.",
    ticket_not_found: "Vald biljett hittades inte",
    no_ticket_selected: "Ingen biljett vald för återbetalning",
    unknown: "Ett okänt fel inträffade",
  },
  success: {
    delete: "Lyckades ta bort {{type}}",
    undelete: "Lyckades återställa {{type}}",
    allocate: "Lyckades tilldela biljetter",
    refund_processed: "Återbetalning genomförd",
  },

  pricing: {
    get_started: "Kom igång",
    contact_us: "Kontakta oss",
    selected_plan: "Valt paket",
    pricing: "Priser",
    most_popular: "Mest populär",
    beta_text:
      "tessera är för närvarande i BETA, därför är alla funktioner inkluderade i gratisplanen.",
    choose_plan: "Välj det paket som passar dig bäst",
    how_to_pay: "Hur vill du betala?",
    monthly: "Månadsvis",
    yearly: "Årsvis",
    discount: "33% rabatt",
    excluding_vat: "Alla priser är exklusive moms.",
    list_of_features: "Lista över funktioner",
    per_event: "/event",
    per_month: "/månad",
    per_year: "/månad",
    plans: {
      free: "Gratis",
    },
    ticket_fees: {
      title: "Biljettavgift",
      description:
        "Biljettavgiften inkluderar moms, alla bank- och kreditkortsavgifter, inga dolda kostnader, vilket ger dig de tjänster du behöver för att lyckas med ditt evenemang.",
      regular_fees: "Vanliga biljettavgifter",
      non_profit_fees: "För ideella organisationer",
      fee_1: "3,5 % + 3 SEK",
      fee_2: "3 % + 1 SEK",
      per_sold_ticket: "Per såld biljett",
    },
  },

  faq: {
    search_placeholder: "Sök efter frågor",
    title: "Vanliga frågor",
    ...seFaq,
  },
};

export default seTranslations;
