import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { INetworkSettings, INetworkSettingsInput, IUser } from "../../types";

interface NetworkSettingsState {
  settings: INetworkSettings | null;
  loading: boolean;
  error: string | null;
  fileUploadProgress: number;
  networkUsers: IUser[] | null;
}

const initialState: NetworkSettingsState = {
  settings: null,
  loading: false,
  error: null,
  fileUploadProgress: 0,
  networkUsers: [],
};

export const updateUploadProgress = createAction<number>(
  "networkSettings/updateUploadProgress"
);

const networkSettingsSlice = createSlice({
  name: "networkSettings",
  initialState,
  reducers: {
    // Existing reducers for network settings
    updateNetworkSettingsRequest: (
      state,
      action: PayloadAction<Omit<INetworkSettingsInput, "logo">>
    ) => {
      state.loading = true;
      state.error = null;
    },
    updateNetworkSettingsSuccess: (
      state,
      action: PayloadAction<INetworkSettings>
    ) => {
      state.settings = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateNetworkSettingsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // New reducers for network users
    getNetworkUsersRequest: (state, action: PayloadAction<number>) => {
      state.loading = true;
      state.error = null;
    },
    getNetworkUsersSuccess: (state, action: PayloadAction<IUser[]>) => {
      state.loading = false;
      state.networkUsers = action.payload;
      state.error = null;
    },
    getNetworkUsersFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addNetworkUserRequest: (
      state,
      action: PayloadAction<{ networkId: number; email: string; role: string }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    addNetworkUserSuccess: (state, action: PayloadAction<IUser>) => {
      state.loading = false;
      state.networkUsers = state.networkUsers
        ? [...state.networkUsers, action.payload]
        : [action.payload];
      state.error = null;
    },
    addNetworkUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeNetworkUserRequest: (
      state,
      action: PayloadAction<{ networkId: number; userId: string }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    removeNetworkUserSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.networkUsers =
        state.networkUsers?.filter((user) => user.id !== action.payload) ||
        null;
      state.error = null;
    },
    removeNetworkUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUploadProgress, (state, action) => {
      state.fileUploadProgress = action.payload;
    });
  },
});

export const {
  updateNetworkSettingsRequest,
  updateNetworkSettingsSuccess,
  updateNetworkSettingsFailure,
  getNetworkUsersRequest,
  getNetworkUsersSuccess,
  getNetworkUsersFailure,
  addNetworkUserRequest,
  addNetworkUserSuccess,
  addNetworkUserFailure,
  removeNetworkUserRequest,
  removeNetworkUserSuccess,
  removeNetworkUserFailure,
} = networkSettingsSlice.actions;

export default networkSettingsSlice.reducer;
