import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import dotenv from "dotenv";
import { PersistGate } from "redux-persist/integration/react";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "./theme/theme_context";

function App() {
  if (!process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID) {
    console.error("REACT_APP_GOOGLE_OAUTH_CLIENT_ID is not set");
    throw new Error("REACT_APP_GOOGLE_OAUTH_CLIENT_ID is not set");
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
        >
          <ThemeProvider>
            <AppRoutes />
          </ThemeProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
